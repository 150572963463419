import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BackdropSection,
  Button,
  SectionContent,
  SimpleIcon,
  TextField,
} from "components";
import { useUpdateGiftcardFac } from "api";
import { toFixed } from "utils";
import { listContentUpdate } from "utils/constants";

export default function AddGiftcardFac() {
  const [fac, setFac] = useState({});

  const { giftcardFac } = useSelector((state) => state.giftcard);

  const [isUpdating, isUpdated, updateFac] = useUpdateGiftcardFac();

  useEffect(() => {
    setFac(giftcardFac);
  }, [giftcardFac]);

  return (
    <div className="h-full bg-background-main p-4">
      <div className="flex flex-row items-center justify-between p-3">
        <div className="w-1/3">
          {[
            {
              btnText: "Update Commission",
              label: "Commission ($)",
              name: "commission",
            },
            {
              btnText: "Update Exchange Rate",
              label: "Exchange Rate ($)",
              name: "exchangeRate",
            },
            {
              btnText: "Update VAT Rate",
              label: "Vat Rate (%)",
              name: "vatRate",
            },
          ].map((item) => (
            <div className="mb-8">
              <TextField
                value={fac[item.name] || ""}
                label={item.label}
                type="number"
                handleChange={(e) =>
                  setFac((prevS) => ({
                    ...prevS,
                    [item.name]: parseFloat(e.target.value),
                  }))
                }
                disableAutoComplete
              />
            </div>
          ))}
          <div className="mt-2 flex-1">
            <Button
              label="Edit Rate Factor"
              handleClick={() => updateFac(fac)}
              isSubmitting={isUpdating}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function UpdateGiftcardFac() {
  const [getData, setGetData] = useState(null);

  const { giftcardFac } = useSelector((state) => state.giftcard);

  const {
    user_details: { position },
  } = useSelector((state) => state.profile);

  const [isUpdating, isUpdated, updateFac] = useUpdateGiftcardFac();

  useEffect(() => {
    if (isUpdated) {
      setGetData(null);
    }
  }, [isUpdated]);

  const handleUpdateValue = () => {
    updateFac(getData);
  };

  return (
    <>
      {listContentUpdate.includes(position) && (
        <>
          {giftcardFac ? (
            <div className="mb-4">
              <SectionContent>
                <div className="flex flex-row items-center justify-between rounded bg-white p-2 dark:bg-slate-800">
                  <div className="flex flex-row items-center justify-start">
                    <div className="flex h-8 w-8 flex-row items-center justify-center rounded bg-slate-50 dark:bg-slate-600">
                      <SimpleIcon icon="ViewAgenda" fontSize={18} />
                    </div>
                    <div>
                      <div className="ml-2">
                        <p className="font-bold  dark:text-gray-50">
                          Giftcard Rate Factors
                        </p>
                        <p className="truncate text-sm text-gray-500 dark:text-gray-200">
                          Edit factor rate
                        </p>
                      </div>
                    </div>
                  </div>

                  <Button
                    label="Save"
                    gradient
                    disabled={!getData}
                    handleClick={handleUpdateValue}
                    isSubmitting={isUpdating}
                  />
                </div>
                <div className="mt-2 ">
                  <div className="flex flex-row gap-2">
                    <ul className="divide-y divide-gray-200 rounded bg-box p-2 dark:divide-gray-700 dark:bg-gray-800">
                      <li className="py-3 sm:py-4">
                        <div className="flex items-start justify-between">
                          <div className="min-w-0 flex-1">
                            <p className="truncate font-medium  dark:text-white">
                              Exchange Rates ($)
                            </p>
                            <div className="mt-2">
                              <p className="truncate py-3 text-gray-500 dark:text-gray-300">
                                {""}
                              </p>
                            </div>
                          </div>
                          {[
                            { id: "NGN", name: "Naira" },
                            { id: "GHS", name: "Cedis" },
                            { id: "XOF", name: "Cefa" },
                          ].map((item) => (
                            <div
                              className="min-w-0 flex-1 pr-6 text-right"
                              key={item.id}
                            >
                              <p className="truncate font-medium dark:text-gray-300">
                                {item.name}
                              </p>
                              <div className="mt-2">
                                <p className="flex flex-row items-end truncate py-3 text-gray-500">
                                  <input
                                    className="w-full border-none bg-transparent text-right outline-none dark:text-white"
                                    defaultValue={toFixed(
                                      giftcardFac?.exchangeRate?.[item?.id] ||
                                        0,
                                      4
                                    )}
                                    placeholder="0.00"
                                    onChange={(e) => {
                                      setGetData({
                                        [`exchangeRate.${item?.id}`]:
                                          parseFloat(e.target.value),
                                      });
                                    }}
                                  />
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </li>
                    </ul>
                    <ul className="divide-y divide-gray-200 rounded bg-box p-2 dark:divide-gray-700 dark:bg-gray-800">
                      {[
                        { id: "commission", name: "Commission ($)" },
                        { id: "vatRate", name: "VAT Rate (%)" },
                      ].map((item) => (
                        <li className="py-3 sm:py-4" key={item.id}>
                          <div className="flex items-center justify-between space-x-4">
                            <div className="min-w-0 flex-1">
                              <p className="truncate font-medium dark:text-white">
                                {item.name}
                              </p>
                              <p className="truncate text-sm text-gray-500 dark:text-gray-300">
                                click to edit
                              </p>
                            </div>

                            <div className="flex min-w-0 flex-1 flex-row items-end justify-end truncate py-3 pr-6 text-gray-500">
                              <input
                                className="w-full border-none bg-transparent text-right outline-none dark:text-white"
                                defaultValue={toFixed(
                                  giftcardFac?.[item.id] || 0,
                                  2
                                )}
                                placeholder="0.00"
                                onChange={(e) => {
                                  setGetData({
                                    [`${item?.id}`]: parseFloat(e.target.value),
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </SectionContent>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
