import React, { useMemo, useState, useEffect, Fragment } from "react";

import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import moment from "moment";
import { TbPlayerTrackNext } from "react-icons/tb";
import { MdOutlineSkipNext } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { useSelector } from "react-redux";

const KaapoMetaAds = () => {
  const [data, setData] = useState([]);
  const { metaAdsInsightsKaapo } = useSelector((state) => state.marketing);

  useEffect(() => {
    setData(metaAdsInsightsKaapo);

    return;
  }, []);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  const columns = useMemo(
    () => [
      {
        header: " ",
        footer: (props) => props.column.id,
        columns: [
          {
            accessorFn: (row) => row.name,
            id: "name",
            cell: (info) => info.getValue(),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold text-gray-600 dark:text-white">
                Name
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) => row.created_time,
            id: "created_time",
            cell: (info) =>
              moment(info.getValue())
                .format("MMM Do YYYY, h:mm:ss a")
                .split(", ")[0],
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold text-gray-600 dark:text-white">
                Created
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) =>
              row.targeting?.publisher_platforms?.map((p) => {
                return (
                  <div className="flex flex-nowrap overflow-auto">
                    <p className="w-32 whitespace-nowrap capitalize">
                      {p.replace("audience_network", " ")}
                    </p>
                  </div>
                );
              }),
            id: "publisher_platforms",
            cell: (info) =>
              info.row.original.targeting?.publisher_platforms ? (
                <p className="">{info.getValue()}</p>
              ) : (
                <p>
                  <BsDashLg className="text-slate-600 dark:text-white" />
                </p>
              ),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold capitalize text-gray-600 dark:text-white ">
                platforms
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) =>
              row.insights?.data?.map((sdate) => {
                return (
                  <div className="flex flex-nowrap overflow-auto">
                    <p className="w-32 whitespace-nowrap capitalize">
                      {new Date(sdate.date_start).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                );
              }),
            id: "start_date",
            cell: (info) =>
              info.row.original?.insights ? (
                <p className="">{info.getValue()}</p>
              ) : (
                <p>
                  <BsDashLg className="text-slate-600 dark:text-white" />
                </p>
              ),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold capitalize text-gray-600 dark:text-white">
                start date
              </span>
            ),
            footer: (props) => props.column.id,
          },

          {
            accessorFn: (row) =>
              row.insights?.data?.map((edate) => {
                return (
                  <div className="flex flex-nowrap overflow-auto">
                    <p className="w-32 whitespace-nowrap capitalize">
                      {new Date(edate.date_stop).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                );
              }),
            id: "date_stop",
            cell: (info) =>
              info.row.original?.insights ? (
                <p className="">{info.getValue()}</p>
              ) : (
                <p>
                  <BsDashLg className="text-slate-600 dark:text-white" />
                </p>
              ),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold capitalize text-gray-600 dark:text-white">
                stop date
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) =>
              row.insights?.data?.map((inlineLink) => {
                return (
                  <div className="flex flex-nowrap overflow-auto">
                    <p className="w-32 whitespace-nowrap capitalize">
                      {inlineLink.inline_link_clicks}
                    </p>
                  </div>
                );
              }),
            id: "inline_link_clicks",
            cell: (info) =>
              info.row.original?.insights ? (
                <p className="">{info.getValue()}</p>
              ) : (
                <p>
                  <BsDashLg className="text-slate-600 dark:text-white" />
                </p>
              ),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold capitalize text-gray-600 dark:text-white">
                link clicks
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) =>
              row.insights?.data?.map((spend) => {
                console.log(spend.spend, "xxxxxxxx");
                return (
                  <div className="flex flex-nowrap overflow-auto">
                    <p className="w-32 capitalize ">
                      {formatter.format(spend.spend)}
                    </p>
                  </div>
                );
              }),
            id: "spend",
            cell: (info) =>
              info.row.original?.insights ? (
                <p className="flex justify-around">{info.getValue()}</p>
              ) : (
                <BsDashLg className="text-slate-600 dark:text-white" />
              ),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold capitalize text-gray-600 dark:text-white">
                spent
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) =>
              row.insights?.data?.map((x) => {
                let postEngagementObject = x.actions.find(
                  (object) => object.action_type === "post_engagement"
                );
                return postEngagementObject.value;
              }),
            id: "post_engagement",
            cell: (info) =>
              info.row.original?.insights ? (
                <p className="">{info.getValue()}</p>
              ) : (
                <p>
                  <BsDashLg className="text-slate-600 dark:text-white" />
                </p>
              ),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold capitalize text-gray-600 dark:text-white">
                post <br /> engagement
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorFn: (row) =>
              row.insights?.data?.map((x) => {
                let conversations = x.actions.find(
                  (object) =>
                    object.action_type ===
                    "onsite_conversion.messaging_conversation_started_7d"
                );
                return conversations?.value;
              }),

            id: "onsite_conversion.messaging_conversation_started_7d",
            cell: (info) =>
              info.row.original.insights ? (
                <p className="">{info.getValue()}</p>
              ) : (
                <p>
                  <BsDashLg className="text-slate-600 dark:text-white" />
                </p>
              ),
            header: () => (
              <span className="flex flex-1 pl-2 font-sans font-semibold capitalize text-gray-600 dark:text-white">
                conversations <br /> started
              </span>
            ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  );

  return (
    <div
      className="h-[500px] w-full overflow-x-auto rounded-md 
      border border-gray-200 bg-white px-3 py-10 shadow-md 
      drop-shadow-md dark:bg-slate-700"
    >
      <Table
        {...{
          data,
          columns,
        }}
        getRowCanExpand={() => true}
      />
    </div>
  );
};

export default KaapoMetaAds;

function Table({ data, columns, renderSubComponent, getRowCanExpand }) {
  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <div className="mx-auto my-auto w-full px-3 py-10">
      <table className="w-full overflow-auto whitespace-nowrap bg-white dark:bg-slate-700">
        <thead className="">
          {table.getHeaderGroups()?.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers?.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div className="">
                        <p className="px-5 text-lg dark:text-white">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </p>

                        {header.column.getCanFilter() ? (
                          <div className="flex w-full flex-1 outline-none">
                            {/* <Filter
                              className="border-none outline-none"
                              column={header.column}
                              table={table}
                            /> */}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="w-full">
          {table.getRowModel().rows?.map((row) => {
            return (
              <Fragment key={row.id}>
                <tr
                  key={row.id}
                  className="mx-10 rounded-md text-slate-600 odd:bg-white even:bg-gray-50 dark:text-white dark:odd:bg-slate-800 dark:even:bg-green-900/30"
                >
                  {row.getVisibleCells()?.map((cell) => {
                    return (
                      <>
                        <td
                          key={cell.id}
                          className="text-break w-full break-words py-2 pl-7 font-sans text-base font-semibold text-gray-600 drop-shadow-md first-letter:capitalize dark:text-white"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      </>
                    );
                  })}
                  <hr />
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="float-right flex items-center gap-2 px-10">
        <button
          className="cursor-pointer rounded border-green-700 bg-green-700 p-1 font-bold text-white drop-shadow-md transition-all delay-150 duration-300 ease-in-out hover:opacity-90"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <TbPlayerTrackNext className="rotate-180" />
        </button>
        <button
          className="cursor-pointer rounded border-green-700 bg-green-700 p-1 font-bold text-white drop-shadow-md transition-all delay-150 duration-300 ease-in-out hover:opacity-90"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <MdOutlineSkipNext className="rotate-180" />
        </button>
        <button
          className="cursor-pointer rounded border-green-700 bg-green-700 p-1 font-bold text-white drop-shadow-md transition-all delay-150 duration-300 ease-in-out hover:opacity-90"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <MdOutlineSkipNext />
        </button>
        <button
          className="cursor-pointer rounded border-green-700 bg-green-700 p-1 font-bold text-white drop-shadow-md transition-all delay-150 duration-300 ease-in-out hover:opacity-90"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <TbPlayerTrackNext />
        </button>
        <span className="flex items-center gap-2">
          <div className="px-4 font-sans text-base font-semibold capitalize text-gray-600 dark:text-white">
            Page
          </div>
          <strong className="text-base font-medium text-gray-600 dark:text-white">
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
      </div>
      <div className="px-4 font-sans text-base font-semibold capitalize text-gray-600 dark:text-white">
        {table.getRowModel().rows?.length} Rows
      </div>
    </div>
  );
}
// function Filter({ column, table }) {
//   const firstValue = table
//     .getPreFilteredRowModel()
//     .flatRows[0]?.getValue(column.id);

//   const columnFilterValue = column.getFilterValue();

//   return typeof firstValue === "number" ? (
//     <div className="flex space-x-2 ">
//       <input
//         type="number"
//         value={columnFilterValue?.[0] ?? ""}
//         onChange={(e) =>
//           column.setFilterValue((old) => [e.target.value, old?.[1]])
//         }
//         placeholder={`Min`}
//         className="w-24 rounded shadow"
//       />
//       <input
//         type="number"
//         value={columnFilterValue?.[1] ?? ""}
//         onChange={(e) =>
//           column.setFilterValue((old) => [old?.[0], e.target.value])
//         }
//         placeholder={`Max`}
//         className="w-24 border rounded shadow"
//       />
//     </div>
//   ) : (
//     <input
//       type="text"
//       value={columnFilterValue ?? ""}
//       onChange={(e) => column.setFilterValue(e.target.value)}
//       placeholder={`Search...`}
//       className="block w-full rounded-sm
//        border-transparent border-gray-300
//        bg-gray-50 p-2.5 font-sans
//        text-sm
//         font-light text-gray-900 outline-none focus-within:border-b-2 focus:border-transparent focus:border-blue-500 focus:border-b-green-500 focus:ring-0"
//     />
//   );
// }
