import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";
import {
  getTransactionStatus,
  getSingleTrans,
  getIsSingleTransLoading,
  getDrawerTrans,
  getIsDrawerTransLoading,
  getWithdrawalFiatBalance,
  getAutomatedList,
  getAutomatedListCount,
} from "redux/reducers/transactionReducers";

import { notification } from "utils";
import { axiosCall, uploadImage } from "api/helpers";
import { transactionDrawer } from "redux/reducers/utilReducers";
import {
  capitalize,
  concat,
  filter,
  groupBy,
  isNull,
  orderBy,
  set,
  uniq,
  update,
} from "lodash";
import dayjs from "dayjs";
import { async } from "@firebase/util";

const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

const auth = i.getAuth();

const url = (endpoint, platform) => {
  const baseUrl = () => {
    switch (platform) {
      case "kaapo":
        return i.baseURL_KP;
      case "esetech":
        return i.baseURL_ET;

      default:
        throw new Error("platform is missing");
    }
  };
  return `${baseUrl()}/transaction/${endpoint}`;
};

export const useGetSingleTrans = (collection, docId, type) => {
  const userUid = auth?.currentUser?.uid;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleTrans(null));
    dispatch(getIsSingleTransLoading(true));
    let unsub;

    try {
      if (userUid && collection && docId && type) {
        const getDB = () => {
          switch (type) {
            case "esetech":
              return i.db;
            case "kaapo":
              return i.db2;
            default:
              throw new Error("Invalid trans");
          }
        };

        const db = getDB();

        unsub = i.onSnapshot(i.doc(db, collection, docId), async (doc) => {
          const { customerId, agentId, deferredByAgent } = doc.data() || {};
          let f;
          const customerRef = await i.getDoc(i.doc(db, "users", customerId));

          let staffRef;
          if (agentId) {
            staffRef = await i.getDoc(i.doc(db, "staff", agentId));
          }
          let deferStaffRef;
          if (deferredByAgent) {
            deferStaffRef = await i.getDoc(i.doc(db, "staff", deferredByAgent));
          }

          if (customerRef.exists()) {
            const { displayName, email, phoneNumber } =
              customerRef?.data() || {};

            let agentObj = {};
            if (staffRef && staffRef.exists()) {
              const { displayName, email, phoneNumber } =
                staffRef?.data() || {};

              agentObj = {
                agentDetails: { displayName, email, phoneNumber },
              };
            }

            let deferAgentObj = {};
            if (deferStaffRef && deferStaffRef.exists()) {
              const { displayName, email } = deferStaffRef?.data() || {};

              deferAgentObj = {
                deferAgentDetails: { displayName, email, phoneNumber },
              };
            }

            f = {
              id: doc.id,
              ...doc.data(),
              customerDetails: { displayName, email, phoneNumber },
              ...agentObj,
              ...deferAgentObj,
              platform: type,
            };
          }
          dispatch(getSingleTrans(f));
          dispatch(getIsSingleTransLoading(false));
        });
      }
    } catch (error) {
      console.log(error);
      dispatch(getIsSingleTransLoading(false));
    }
    return unsub;
  }, [dispatch, userUid, collection, docId]);
};

export const useGetDrawerTrans = (collection, docId, type) => {
  const userUid = auth?.currentUser?.uid;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDrawerTrans(null));
    dispatch(getIsDrawerTransLoading(true));
    dispatch(transactionDrawer(true));
    let unsub;

    try {
      if (userUid && collection && docId && type) {
        const getDB = () => {
          switch (type) {
            case "esetech":
              return i.db;
            case "kaapo":
              return i.db2;

            default:
              throw new Error("Invalid trans");
          }
        };

        const db = getDB();
        unsub = i.onSnapshot(i.doc(db, collection, docId), async (doc) => {
          const { customerId, agentId, deferredByAgent, label } =
            doc.data() || {};
          let f;
          const isCustomerId = customerId || label;
          const customerRef = await i.getDoc(i.doc(db, "users", isCustomerId));

          let staffRef;
          if (agentId) {
            staffRef = await i.getDoc(i.doc(db, "staff", agentId));
          }

          let deferStaffRef;
          if (deferredByAgent) {
            deferStaffRef = await i.getDoc(i.doc(db, "staff", deferredByAgent));
          }

          if (customerRef.exists()) {
            const { displayName, email, phoneNumber } =
              customerRef?.data() || {};

            let agentObj = {};
            if (staffRef && staffRef.exists()) {
              const { displayName, email, phoneNumber } =
                staffRef?.data() || {};

              agentObj = {
                agentDetails: { displayName, email, phoneNumber },
              };
            }

            let deferAgentObj = {};
            if (deferStaffRef && deferStaffRef.exists()) {
              const { displayName, email } = deferStaffRef?.data() || {};

              deferAgentObj = {
                deferAgentDetails: { displayName, email, phoneNumber },
              };
            }

            f = {
              id: doc.id,
              ...doc.data(),
              customerDetails: { displayName, email, phoneNumber },
              ...agentObj,
              ...deferAgentObj,
              platform: type,
            };
          }
          dispatch(getDrawerTrans(f));
          dispatch(getIsDrawerTransLoading(false));
        });
      }
    } catch (error) {
      console.log(error);
      dispatch(getIsDrawerTransLoading(false));
    }
    return unsub;
  }, [dispatch, userUid, collection, docId]);
};

export const useClaimTrade = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const claimTrade = async (values) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
    };

    const { platform } = values;

    try {
      dispatch(getTransactionStatus(true));
      setSubmitting(true);

      const trans = await axiosCall(
        url("claim_trans", platform),
        valueFormat,
        true,
        platform
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [isSubmitting, isSubmitted, claimTrade];
};

export const useGenerateCode = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [getTransCode, setTransCode] = useState(null);

  const { singleTrans } = useStore().getState().transaction;

  const dispatch = useDispatch();

  const generateCode = async (values) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
    };

    try {
      if (singleTrans?.id !== values?.transactionId) {
        throw new Error("invalid params");
      }

      dispatch(getTransactionStatus(true));
      setSubmitting(true);
      setTransCode(null);

      const { platform } = values;

      const trans = await axiosCall(
        url("generate_trans_code", platform),
        valueFormat,
        true,
        platform
      );

      console.log(trans);
      setSubmitting(false);
      setSubmitted(true);
      setTransCode(trans?.data?.data?.transCode);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      notification(
        "danger",
        error.message === "invalid params"
          ? "Invalid Params"
          : "An error occured! Try again later.",
        "error"
      );
    }
  };

  return [isSubmitting, isSubmitted, getTransCode, generateCode];
};

export const useCompleteTrans = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const { singleTrans } = useStore().getState().transaction;

  const completeTrade = async (values, images, imageUID) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
    };

    try {
      const { collectionTo, platform } = values;
      let declineConfirmationUpload = null;

      setSubmitting(true);

      if (
        singleTrans?.id !== values?.transactionId &&
        imageUID !== singleTrans?.imageUID
      ) {
        throw new Error("invalid trans");
      }

      if (imageUID && images.length) {
        declineConfirmationUpload = await uploadImage(
          images,
          `${collectionTo}/${imageUID}`,
          null,
          platform
        );
      }

      const trans = await axiosCall(
        url("process_trans", platform),
        { ...valueFormat, declineConfirmationUpload },
        true,
        platform
      );

      // console.log(trans);
      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Transaction Completed");
    } catch (error) {
      console.log(error);
      setSubmitting(false);

      notification(
        "danger",
        `${JSON.stringify(error) || "An error occured. Try again later"}`,
        "error"
      );
    }
  };

  return [isSubmitting, isSubmitted, completeTrade];
};

export const useDeferTrans = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const deferTrade = async (values) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
    };

    try {
      dispatch(getTransactionStatus(true));
      setSubmitting(true);

      const { platform } = values;

      await axiosCall(
        url("defer_trans", platform),
        valueFormat,
        true,
        platform
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      notification(
        "danger",
        `${JSON.stringify(error) || "An error occured. Try again later"}`,
        "error"
      );
    }
  };

  return [isSubmitting, isSubmitted, deferTrade];
};

export const useGetAccountBalances = () => {
  // const [isSubmitting, setSubmitting] = useState(false);
  // const [isSubmitted, setSubmitted] = useState(false);
  // const [isError, setError] = useState(false);
  const { user_details } = useStore().getState().profile || {};
  const { refreshSideInfo } = useStore().getState().util || {};
  const { accountBalance } = refreshSideInfo || {};

  const dispatch = useDispatch();

  const getAccountBalances = async () => {
    if (auth.currentUser && user_details.roles.payout) {
      const valueFormat = {
        // ...values,
        agentId: auth.currentUser.uid,
      };

      try {
        // setSubmitting(true);
        // setError(false);
        dispatch(getWithdrawalFiatBalance(null));
        const transEse = await axiosCall(
          url("get_fiat_balance", "esetech"),
          valueFormat,
          true,
          "esetech"
        );

        const transKap = await axiosCall(
          url("get_fiat_balance", "kaapo"),
          valueFormat,
          true,
          "kaapo"
        );

        const transEseObj = transEse?.data?.data;
        const transKapObj = transKap?.data?.data;
        const transConcat = concat(transEseObj, transKapObj);
        const transConcatFormatted = transConcat.map((item) => ({
          ...item,
        }));
        const isAnyBalanceLow = transConcatFormatted.some(
          (i) => i.isBalanceLow
        );

        const accountBal = groupBy(transConcatFormatted, (o) => o.platform);

        // console.log(accountBal, "accountBalance");

        dispatch(
          getWithdrawalFiatBalance({
            accountBalance: accountBal,
            accountIsLow: isAnyBalanceLow,
          })
        );

        // setSubmitting(false);
        // setSubmitted(true);
      } catch (error) {
        console.log(error);
        dispatch(getWithdrawalFiatBalance(null));

        // setSubmitting(false);
        // notification("danger", "An error occured! Try again later.", "error");
      }
    }
  };

  useEffect(() => {
    getAccountBalances();
    let interval = setInterval(() => {
      getAccountBalances();
    }, 1000 * 60 * 5);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, user_details, accountBalance]);
};

export const useGetAutomatedList = ({
  dispatcherKey,
  collection,
  platform,
  DB,
}) => {
  const { user_details } = useStore().getState().profile || {};

  const dispatch = useDispatch();
  const getList = async (querySnapshot) => {
    if (querySnapshot.size) {
      const t = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const {
            customerId,
            transId,
            fiatTransferProvider,
            product,
            withdrawDetails,
            dateCreated,
            productPlan,
            productType,
            provider,
          } = doc?.data() || {};

          const { accountDetails } = withdrawDetails || {};

          let f = {};
          const customerRef = await i.getDoc(i.doc(DB, "users", customerId));
          const valueFormat = {
            transId,
            fiatTransferProvider,
            dateCreated,
            agentId: auth.currentUser.uid,
            type: product,
          };

          const trans = await axiosCall(
            url("get_withdrawals_list", platform),
            valueFormat,
            true,
            platform
          );

          const otherDetails = trans?.data?.data;

          const transationDetails =
            otherDetails?.transationDetails ||
            `${accountDetails?.accountName || productPlan || productType}/${
              accountDetails?.bankName || provider
            }`;

          if (customerRef.exists()) {
            const { displayName, email } = customerRef?.data() || {};

            f = {
              id: doc.id,
              ...doc.data(),
              otherDetails: { ...otherDetails, transationDetails },
              userDetails: { displayName, email },
            };
          } else {
            f = null;
          }

          return f;
        })
      );
      const tResult = orderBy(
        filter(t, (i) => !isNull(i)),
        ["dateCreated"],
        ["desc"]
      );

      dispatch(getAutomatedList({ [dispatcherKey]: tResult }));
      dispatch(getAutomatedListCount({ [dispatcherKey]: querySnapshot.size }));
    } else {
      dispatch(getAutomatedList({ [dispatcherKey]: [] }));
      dispatch(getAutomatedListCount({ [dispatcherKey]: 0 }));
    }
  };

  useEffect(() => {
    let unsubWithD;
    let intervalId;
    if (auth?.currentUser && user_details?.roles?.payout) {
      try {
        const qWithD = i.query(
          i.collection(DB, collection),
          i.where("isAutomatic", "==", true)
        );

        unsubWithD = i.onSnapshot(qWithD, async (querySnapshot) => {
          await getList(querySnapshot);
        });

        intervalId = setInterval(async () => {
          const querySnapshotWithD = await i.getDocs(qWithD);

          await getList(querySnapshotWithD);
        }, 1000 * 60 * 1);
      } catch (error) {
        console.log(error);
      }
    }

    return () => {
      if (unsubWithD) unsubWithD();

      clearInterval(intervalId);
    };
  }, [user_details, dispatch]);
};

export const useGetAutomatedTrans = () => {
  useGetAutomatedList({
    dispatcherKey: "esetechWithdraw",
    collection: "pendingTransWithdrawals",
    platform: "esetech",
    DB: i.db,
  });
  useGetAutomatedList({
    dispatcherKey: "kaapoWithdraw",
    collection: "pendingTransWithdrawals",
    platform: "kaapo",
    DB: i.db2,
  });
  useGetAutomatedList({
    dispatcherKey: "esetechUtility",
    collection: "pendingTransUtilities",
    platform: "esetech",
    DB: i.db,
  });
  useGetAutomatedList({
    dispatcherKey: "kaapoUtility",
    collection: "pendingTransUtilities",
    platform: "kaapo",
    DB: i.db2,
  });
};

///////////////////

export const useGetFiatProviderBalance = (platform) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [data, setData] = useState(null);
  const { transReportDateRange } = useStore().getState().util || {};

  const { startDateString, endDateString, calendarType } =
    transReportDateRange || {};

  useEffect(() => {
    const startDate = startDateString;
    const endDate = endDateString;

    const getBal = async () => {
      try {
        setSubmitting(true);
        setData(null);
        if (calendarType === "day") {
          const valueFormat = {
            transId: "trans_id_1111",
            startDate,
            endDate,
          };
          const trans = await axiosCall(
            url("get_withdrawal_trans_total", platform),
            valueFormat,
            true,
            platform
          );
          setSubmitting(false);
          setSubmitted(true);
          const transTT = trans?.data?.data;
          // console.log(transTT, "asdjasdjkasdjk");
          setData(transTT);
        } else {
          setSubmitting(false);
          setSubmitted(true);
          setData(null);
        }
      } catch (error) {
        setData(null);
      }
    };

    getBal();
  }, [calendarType, endDateString, platform, startDateString]);

  return [data, isSubmitting, isSubmitted];
};

export const useTransReportWithdrawal = (platform = "esetech") => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [data, setData] = useState(null);

  const { transReportDateRange } = useStore().getState().util || {};

  const { startDateValueOf, endDateValueOf, calendarType } =
    transReportDateRange || {};

  const isEsetechPlatform = platform === "esetech";
  let fiatProviderList;
  if (isEsetechPlatform) {
    fiatProviderList = ["Paga", "Lenco"];
  } else {
    fiatProviderList = ["Monnify"];
  }

  useEffect(() => {
    if (transReportDateRange) {
      const fiatType = ["naira", "cedis", "cefa"];

      setSubmitting(true);
      setData(null);

      const getData = async () => {
        const DB = isEsetechPlatform ? i.db : i.db2;
        try {
          let querySnapshotOldDocs;

          const q = i.query(
            i.collection(DB, "transWithdrawals"),
            i.where("dateCreated", ">=", startDateValueOf),
            i.where("dateCreated", "<=", endDateValueOf)
          );

          if (isEsetechPlatform) {
            const qOld = i.query(
              i.collection(i.db, "withdrawalRequest"),
              i.where("dateCreated", ">=", startDateValueOf),
              i.where("dateCreated", "<=", endDateValueOf)
            );
            const getOldDocs = await i.getDocs(qOld);
            querySnapshotOldDocs = getOldDocs.docs;
          } else {
            querySnapshotOldDocs = [];
          }

          const querySnapshot = await i.getDocs(q);
          // const querySnapshotOld = await i.getDocs(qOld);

          const d = [...querySnapshotOldDocs, ...querySnapshot?.docs]?.reduce(
            (acc, doc) => {
              const {
                type: withDType,
                withdrawDetails,
                isAutomatic,
                fiatTransferProvider,
              } = doc?.data();

              const { amount: amountObj } = withdrawDetails || {};
              const { charges, amount } = amountObj || {};

              const getIsAutomatic = isAutomatic ? "isAutomatic" : "isManual";

              const provider =
                fiatTransferProvider ||
                (isEsetechPlatform ? "lenco" : "monnify");

              const type = fiatType.includes(withDType) ? withDType : "naira";

              acc[`${type}Count`] = (acc[`${type}Count`] || 0) + 1;
              acc[`${type}Amount`] = (acc[`${type}Amount`] || 0) + amount;
              acc[`${type}Charges`] = (acc[`${type}Charges`] || 0) + charges;

              acc[`${getIsAutomatic}Count`] =
                (acc[`${getIsAutomatic}Count`] || 0) + 1;

              acc[`${getIsAutomatic}Count${capitalize(type)}`] =
                (acc[`${getIsAutomatic}Count${capitalize(type)}`] || 0) + 1;
              acc[`${getIsAutomatic}Count${capitalize(provider)}`] =
                (acc[`${getIsAutomatic}Count${capitalize(provider)}`] || 0) + 1;

              acc[`${getIsAutomatic}Amount`] =
                (acc[`${getIsAutomatic}Amount`] || 0) + amount;
              acc[`${getIsAutomatic}Amount${capitalize(type)}`] =
                (acc[`${getIsAutomatic}Amount${capitalize(type)}`] || 0) +
                amount;
              acc[`${getIsAutomatic}Amount${capitalize(provider)}`] =
                (acc[`${getIsAutomatic}Amount${capitalize(provider)}`] || 0) +
                amount;

              return acc;
            },
            {}
          );

          setSubmitting(false);
          setSubmitted(true);
          setData((prevS) => ({ ...prevS, ...d }));
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, [startDateValueOf, endDateValueOf, calendarType]);

  return [isSubmitting, isSubmitted, data, fiatProviderList];
};

export const useTransReportCryptoEse = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [data, setData] = useState(null);

  const { transReportDateRange } = useStore().getState().util || {};

  const {
    startDateValueOf,
    endDateValueOf,
    startDateValueOfPrev1,
    endDateValueOfPrev1,
    startDateValueOfPrev2,
    endDateValueOfPrev2,
    calendarType,
  } = transReportDateRange || {};

  const isCustomerNew = async (uid) => {
    const docRef = i.doc(i.db, "users", uid);
    const docSnap = await i.getDoc(docRef);
    const { dateCreated } = docSnap.data() || {};

    if (docSnap.exists()) {
      if (dateCreated >= startDateValueOf && dateCreated <= endDateValueOf) {
        return "current";
      } else if (
        dateCreated >= startDateValueOfPrev1 &&
        dateCreated <= endDateValueOfPrev1
      ) {
        return "prevOne";
      } else if (
        dateCreated >= startDateValueOfPrev2 &&
        dateCreated <= endDateValueOfPrev2
      ) {
        return "prevTwo";
      }

      return "past";
    }
    return false;
  };
  useEffect(() => {
    if (transReportDateRange) {
      setSubmitting(true);
      setData(null);

      const getData = async () => {
        try {
          const q = i.query(
            i.collection(i.db, "transCrypto"),
            i.where("dateCreated", ">=", startDateValueOf),
            i.where("dateCreated", "<=", endDateValueOf)
          );
          const qOld = i.query(
            i.collection(i.db, "transactions"),
            i.where("product", "==", "crypto"),
            i.where("dateCreated", ">=", startDateValueOf),
            i.where("dateCreated", "<=", endDateValueOf)
          );

          const querySnapshot = await i.getDocs(q);
          const querySnapshotOld = await i.getDocs(qOld);

          const tradeD = {};
          const tradeDTotal = {};
          const receiveD = {};
          const receiveUserD = {};

          const getUserNo = async (type) => {
            const d = {
              currentStart: startDateValueOf,
              currentEnd: endDateValueOf,
              prevOneStart: startDateValueOfPrev1,
              prevOneEnd: endDateValueOfPrev1,
              prevTwoStart: startDateValueOfPrev2,
              prevTwoEnd: endDateValueOfPrev2,
            };
            const q = i.query(
              i.collection(i.db, "users"),
              ...(["all", "past"].includes(type)
                ? [i.where("dateCreated", "<=", endDateValueOf)]
                : [
                    i.where("dateCreated", ">=", d[`${type}Start`]),
                    i.where("dateCreated", "<=", d[`${type}End`]),
                  ])
            );

            return (await i.getCountFromServer(q)).data().count;
          };

          const listUser = ["all", "current", "prevOne", "prevTwo", "past"];

          const userCount = (
            await Promise.all(
              listUser.map(async (item) => {
                const d = await getUserNo(item);
                return d;
              })
            )
          ).reduce((acc, item, index) => {
            acc[listUser?.[index]] = item;
            return acc;
          }, {});

          await [...querySnapshotOld?.docs, ...querySnapshot?.docs]?.reduce(
            async (acc, doc) => {
              const { transaction, customerId } = doc?.data();

              const {
                tradeType,
                fiat,
                rate,
                fiatRate: fiatRateF,
                fiatSym: fiatSymF,
                sym,
                usd: usdF,
                totalUsd,
                crypto: cryptoF,
                feesUsd,
              } = transaction || {};

              const fiatSym = fiatSymF || "NGN";
              const fiatRate = fiatRateF || rate;
              const usd = parseFloat(usdF);
              const crypto = parseFloat(cryptoF);

              if (["buy", "sell", "convert"].includes(tradeType)) {
                const setData = (obj, paramsto, paramsFrom) => {
                  set(obj, paramsto, {
                    count: (paramsFrom?.["count"] || 0) + 1,
                    amount: (paramsFrom?.["amount"] || 0) + usd,
                    amountCrypto: (paramsFrom?.["amountCrypto"] || 0) + crypto,
                    fees: (paramsFrom?.["fees"] || 0) + feesUsd,
                    [`amount${fiatSym}`]:
                      (paramsFrom?.[`amount${fiatSym}`] || 0) + usd * fiatRate,
                    amountConvert:
                      (paramsFrom?.["amountConvert"] || 0) + totalUsd,
                  });
                };

                setData(
                  tradeD,
                  `[${tradeType}][${fiatSym}][${sym}][${fiatRate}]`,
                  tradeD?.[tradeType]?.[fiatSym]?.[sym]?.[fiatRate]
                );

                setData(
                  tradeDTotal,
                  `${tradeType}.${fiatSym}`,
                  tradeDTotal?.[tradeType]?.[fiatSym]
                );
              }

              if (tradeType === "receive") {
                const isNew = await isCustomerNew(customerId);

                if (isNew) {
                  const list = receiveUserD?.[`${isNew}`]?.["list"]
                    ? receiveUserD?.[`${isNew}`]?.["list"]
                    : [];
                  list.push(customerId);

                  set(receiveUserD, `${isNew}`, {
                    list: list,
                    count: list?.length,
                    uniqCount: uniq(list)?.length,
                    amount: (receiveUserD?.[`${isNew}`]?.["amount"] || 0) + usd,
                    // allUserCount: userCount[isNew],
                  });
                }

                const list = receiveUserD?.[`all`]?.["list"]
                  ? receiveUserD?.["all"]?.["list"]
                  : [];
                list.push(customerId);

                set(receiveUserD, `all`, {
                  list: list,
                  count: list?.length,
                  uniqCount: uniq(list)?.length,
                  amount: (receiveUserD?.[`all`]?.["amount"] || 0) + usd,
                  // allUserCount: userCount["all"],
                });

                listUser.forEach((i) => {
                  set(receiveUserD, `${i}`, {
                    ...receiveUserD[i],
                    allUserCount: userCount[i],
                  });
                });

                set(receiveD, `${tradeType}${sym}`, {
                  count: (receiveD?.[`${tradeType}${sym}`]?.["count"] || 0) + 1,
                  amountCrypto:
                    (receiveD?.[`${tradeType}${sym}`]?.["amountCrypto"] || 0) +
                    crypto,
                  amountUSD:
                    (receiveD?.[`${tradeType}${sym}`]?.["amountUSD"] || 0) +
                    usd,
                });

                set(receiveD, `receiveTotal`, {
                  count: (receiveD?.[`receiveTotal`]?.["count"] || 0) + 1,
                  amountCrypto: "-",
                  amountUSD:
                    (receiveD?.[`receiveTotal`]?.["amountUSD"] || 0) + usd,
                });
              }
              return acc;
            },
            {}
          );

          setSubmitting(false);
          setSubmitted(true);
          setData({
            tradeD,
            tradeDTotal,
            receiveD,
            receiveUserD,
          });
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, [startDateValueOf, endDateValueOf, calendarType]);
  return [isSubmitting, isSubmitted, data];
};

export const useTransReportUtility = (platform = "esetech") => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [data, setData] = useState(null);

  const { transReportDateRange } = useStore().getState().util || {};

  const { startDateValueOf, endDateValueOf, calendarType } =
    transReportDateRange || {};

  const isEsetechPlatform = platform === "esetech";

  useEffect(() => {
    if (transReportDateRange) {
      setSubmitting(true);
      setData(null);

      const getData = async () => {
        try {
          const DB = isEsetechPlatform ? i.db : i.db2;
          const q = i.query(
            i.collection(DB, "transUtilities"),
            i.where("status", "==", "approved"),
            i.where("dateCreated", ">=", startDateValueOf),
            i.where("dateCreated", "<=", endDateValueOf)
          );

          const querySnapshot = await i.getDocs(q);

          const utilityD = {};
          const utilityConvtAirt = {};

          const list = ["airtime", "data-bundle", "tv-cable", "electricity"];

          querySnapshot?.forEach((doc) => {
            const { productType, amount, amountSold } = doc?.data();

            const type = productType;

            if (list.includes(productType)) {
              set(utilityD, `${type}`, {
                count: (utilityD?.[`${type}`]?.["count"] || 0) + 1,
                amountNGN: (utilityD?.[`${type}`]?.["amountNGN"] || 0) + amount,
              });

              set(utilityD, "total", {
                count: (utilityD?.["total"]?.["count"] || 0) + 1,
                amountNGN: (utilityD?.["total"]?.["amountNGN"] || 0) + amount,
              });
            }
            if (productType === "convert-airtime") {
              set(utilityConvtAirt, `${type}`, {
                count: (utilityConvtAirt?.[`${type}`]?.["count"] || 0) + 1,
                amountNGNSell:
                  (utilityConvtAirt?.[`${type}`]?.["amountNGNSell"] || 0) +
                  amountSold,
                amountNGNBuy:
                  (utilityConvtAirt?.[`${type}`]?.["amountNGNBuy"] || 0) +
                  amount,
              });

              set(utilityConvtAirt, "total", {
                count: (utilityConvtAirt?.["total"]?.["count"] || 0) + 1,
                amountNGNSell:
                  (utilityConvtAirt?.["total"]?.["amountNGNSell"] || 0) +
                  amountSold,
                amountNGNBuy:
                  (utilityConvtAirt?.["total"]?.["amountNGNBuy"] || 0) + amount,
              });
            }
          });

          setSubmitting(false);
          setSubmitted(true);
          setData({
            utilityD,
            utilityConvtAirt,
          });
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, [startDateValueOf, endDateValueOf, calendarType]);
  return [isSubmitting, isSubmitted, data];
};

export const useTransReportGiftcard = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [data, setData] = useState(null);

  const { transReportDateRange } = useStore().getState().util || {};

  const {
    startDateValueOf,
    endDateValueOf,
    startDateValueOfPrev1,
    endDateValueOfPrev1,
    startDateValueOfPrev2,
    endDateValueOfPrev2,
    calendarType,
  } = transReportDateRange || {};

  useEffect(() => {
    if (transReportDateRange) {
      setSubmitting(true);
      setData(null);

      const isCustomerNew = async (uid) => {
        const docRef = i.doc(i.db2, "users", uid);
        const docSnap = await i.getDoc(docRef);
        const { dateCreated } = docSnap.data() || {};

        if (docSnap.exists()) {
          if (
            dateCreated >= startDateValueOf &&
            dateCreated <= endDateValueOf
          ) {
            return "current";
          } else if (
            dateCreated >= startDateValueOfPrev1 &&
            dateCreated <= endDateValueOfPrev1
          ) {
            return "prevOne";
          } else if (
            dateCreated >= startDateValueOfPrev2 &&
            dateCreated <= endDateValueOfPrev2
          ) {
            return "prevTwo";
          }

          return "past";
        }
        return false;
      };

      const getUserNo = async (type) => {
        const d = {
          currentStart: startDateValueOf,
          currentEnd: endDateValueOf,
          prevOneStart: startDateValueOfPrev1,
          prevOneEnd: endDateValueOfPrev1,
          prevTwoStart: startDateValueOfPrev2,
          prevTwoEnd: endDateValueOfPrev2,
        };
        const q = i.query(
          i.collection(i.db2, "users"),
          ...(["all", "past"].includes(type)
            ? [i.where("dateCreated", "<=", endDateValueOf)]
            : [
                i.where("dateCreated", ">=", d[`${type}Start`]),
                i.where("dateCreated", "<=", d[`${type}End`]),
              ])
        );

        return (await i.getCountFromServer(q)).data().count;
      };

      const listUser = ["all", "current", "prevOne", "prevTwo", "past"];

      const getData = async () => {
        try {
          const userCount = (
            await Promise.all(
              listUser.map(async (item) => {
                const d = await getUserNo(item);
                return d;
              })
            )
          ).reduce((acc, item, index) => {
            acc[listUser?.[index]] = item;
            return acc;
          }, {});

          const q = i.query(
            i.collection(i.db2, "transGiftcards"),
            i.where("product", "==", "giftcard"),
            i.where("status", "==", "approved"),
            i.where("dateCreated", ">=", new Date(startDateValueOf)),
            i.where("dateCreated", "<=", new Date(endDateValueOf))
          );
          const qOld = i.query(
            i.collection(i.db, "transactions"),
            i.where("product", "==", "giftcard"),
            i.where("status", "==", "approved"),
            i.where("dateCreated", ">=", startDateValueOf),
            i.where("dateCreated", "<=", endDateValueOf)
          );

          const querySnapshot = await i.getDocs(q);
          const querySnapshotOld = await i.getDocs(qOld);

          const tradeD = {};
          const tradeTotalD = {};
          const tradeUserD = {};

          await [...querySnapshotOld?.docs, ...querySnapshot?.docs]?.reduce(
            async (acc, doc) => {
              const { transaction, customerId, amount: amountT } = doc?.data();

              const { card, qty, tradeType, total } = transaction || {};
              const amount = amountT || total;
              const qtyCount = Object.entries(qty).reduce((acc, item) => {
                acc += item[1]?.count;
                return acc;
              }, 0);

              if (tradeType === "sell") {
                const isNew = await isCustomerNew(customerId);

                if (isNew) {
                  const list = tradeUserD?.[`${isNew}`]?.["list"] || [];
                  list.push(customerId);

                  set(tradeUserD, `${isNew}`, {
                    list: list,
                    count: list?.length,
                    uniqCount: uniq(list)?.length,
                    amount:
                      (tradeUserD?.[`${isNew}`]?.["amount"] || 0) + amount,
                    // allUserCount: userCount[isNew],
                  });
                }

                const list = tradeUserD?.[`all`]?.["list"] || [];
                list.push(customerId);

                set(tradeUserD, `all`, {
                  list: list,
                  count: list?.length,
                  uniqCount: uniq(list)?.length,
                  amount: (tradeUserD?.[`all`]?.["amount"] || 0) + amount,
                  // allUserCount: userCount["all"],
                });

                listUser.forEach((i) => {
                  set(tradeUserD, `${i}`, {
                    ...tradeUserD[i],
                    allUserCount: userCount[i],
                  });
                });

                set(tradeD, `${card}`, {
                  count: (tradeD?.[`${card}`]?.["count"] || 0) + 1,
                  countByCard:
                    (tradeD?.[`${card}`]?.["countByCard"] || 0) + qtyCount,
                  amountNGN: (tradeD?.[`${card}`]?.["amountNGN"] || 0) + amount,
                });

                set(tradeTotalD, `total`, {
                  count: (tradeTotalD?.[`total`]?.["count"] || 0) + 1,
                  countByCard:
                    (tradeTotalD?.[`total`]?.["countByCard"] || 0) + qtyCount,
                  amountNGN:
                    (tradeTotalD?.[`total`]?.["amountNGN"] || 0) + amount,
                });
              }
              return acc;
            },
            {}
          );

          setSubmitting(false);
          setSubmitted(true);
          setData({
            tradeD,
            tradeTotalD,
            tradeUserD,
          });
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, [startDateValueOf, endDateValueOf, calendarType]);
  return [isSubmitting, isSubmitted, data];
};

export const useTransReportBonus = (platform = "esetech") => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [data, setData] = useState(null);

  const { transReportDateRange } = useStore().getState().util || {};

  const { startDateValueOf, endDateValueOf, calendarType } =
    transReportDateRange || {};

  const isEsetechPlatform = platform === "esetech";

  useEffect(() => {
    if (transReportDateRange) {
      setSubmitting(true);
      setData(null);

      const getData = async () => {
        try {
          const DB = isEsetechPlatform ? i.db : i.db2;
          const q = i.query(
            i.collection(DB, "transTransferBonus"),
            i.where("status", "==", "approved"),
            i.where("dateCreated", ">=", startDateValueOf),
            i.where("dateCreated", "<=", endDateValueOf)
          );

          const querySnapshot = await i.getDocs(q);

          const bonusReceive = {};
          const bonusWithdraw = {};

          querySnapshot?.forEach((doc) => {
            const { productType, bonusDescription, amount } = doc?.data();

            const type = bonusDescription;

            const bonusList =
              productType === "withdraw-bonus-naira"
                ? bonusWithdraw
                : bonusReceive;

            set(bonusList, `${type}`, {
              count: (bonusList?.[`${type}`]?.["count"] || 0) + 1,
              amountNGN: (bonusList?.[`${type}`]?.["amountNGN"] || 0) + amount,
            });

            set(bonusList, "total", {
              count: (bonusList?.["total"]?.["count"] || 0) + 1,
              amountNGN: (bonusList?.["total"]?.["amountNGN"] || 0) + amount,
            });
          });

          setSubmitting(false);
          setSubmitted(true);
          setData({
            bonusReceive,
            bonusWithdraw,
          });
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, [startDateValueOf, endDateValueOf, calendarType]);
  return [isSubmitting, isSubmitted, data];
};
