import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";

import {
  getPendingCryptoTransEse,
  getPendingWithdrawalFiatTransEse,
  getActiveWithdrawalFiatTransEse,
  getDeferredWithdrawalFiatTransEse,
  getCompletedCryptoTransEse,
  getCompletedOldCryptoTransEse,
  getCompletedOldGiftcardTransEse,
  getCompletedOldWithdrawalFiatTransEse,
  getCompletedWithdrawalFiatTransEse,
  getCompletedUtilityTransEse,
  getCompletedFundWalletTransEse,
  getCompletedTransferFiatTransEse,
  getCompletedTransferBonusTransEse,
  getCompletedConvertFiatTransEse,
} from "redux/reducers/transactionEseReducers";

import { axiosCall } from "api/helpers";
import { filter, isNull } from "lodash";
import { notification } from "utils";
import { getWithdrawalFiatBalance } from "redux/reducers/transactionReducers";

const auth = i.getAuth();

const url = (endpoint) => `${i.baseURL_ET}/transaction/${endpoint}`;

const unCompleted = (role, position, whatRole) => {
  return (
    (position === "Customer Care Agent" && role.includes(whatRole)) ||
    ["Supervisor", "Chief Executive Officer", "HR Manager"].includes(position)
  );
};

const completed = (role, position, whatRole) => {
  return (
    position === "Customer Care Agent" ||
    [
      "Supervisor",
      "Chief Executive Officer",
      "HR Manager",
      "Accounting Officer",
    ].includes(position)
  );
};

const unCompletedAlt = (position) => {
  return [
    "Chief Executive Officer",
    "HR Manager",
    "Accounting Officer",
  ].includes(position);
};

const completedTrans =
  (collection, product, whatRole) =>
  (agentId, role, position, startDate, endDate) =>
    completed(role, position, whatRole)
      ? [
          i.collection(i.db, collection),
          ...(position === "Customer Care Agent"
            ? [i.where("agentId", "==", agentId)]
            : []),
          i.where("product", "==", product),
          i.where("status", "in", ["approved", "declined"]),
          ...(startDate
            ? [
                i.where(
                  "dateCreated",
                  ">=",
                  product === "giftcard" ? new Date(startDate) : startDate
                ),
              ]
            : []),
          ...(endDate
            ? [
                i.where(
                  "dateCreated",
                  "<=",
                  product === "giftcard" ? new Date(endDate) : endDate
                ),
              ]
            : []),
          i.orderBy("dateCreated", "desc"),
          ...(!(startDate && endDate) ? [i.limit(100)] : []),
        ]
      : [];

const completedOldTrans =
  (collection, product, whatRole) =>
  (agentId, role, position, startDate, endDate) =>
    completed(role, position, whatRole)
      ? [
          i.collection(i.db, collection),
          ...(position === "Customer Care Agent"
            ? [i.where("agentId", "==", agentId)]
            : []),
          i.where("product", "==", product),
          i.where("status", "in", ["approved", "declined"]),
          ...(startDate ? [i.where("dateCreated", ">=", startDate)] : []),
          ...(endDate ? [i.where("dateCreated", "<=", endDate)] : []),
          i.orderBy("dateCreated", "desc"),
          ...(!(startDate && endDate) ? [i.limit(100)] : []),
        ]
      : [];

const completedTransAlt =
  (collection, product) => (agentId, role, position, startDate, endDate) =>
    unCompletedAlt(position)
      ? [
          i.collection(i.db, collection),
          i.where("product", "==", product),
          i.where("status", "in", ["approved", "declined"]),
          ...(startDate
            ? [
                i.where(
                  "dateCreated",
                  ">=",
                  product === "giftcard" ? new Date(startDate) : startDate
                ),
              ]
            : []),
          ...(endDate
            ? [
                i.where(
                  "dateCreated",
                  "<=",
                  product === "giftcard" ? new Date(endDate) : endDate
                ),
              ]
            : []),
          i.orderBy("dateCreated", "desc"),
          ...(!(startDate && endDate) ? [i.limit(100)] : []),
        ]
      : [];

const pendingCryptoQueriesESE = (agentId, role, position) =>
  ["Supervisor", "Chief Executive Officer", "HR Manager"].includes(position)
    ? [
        i.collection(i.db, "bitPowrCryptoNotifications"),
        i.orderBy("created_at", "desc"),
      ]
    : [];

const pendingWithdrawFiatQueriesESE = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db, "pendingTransWithdrawals"),
        i.where("agentId", "==", null),
        i.where("product", "==", "payout"),
        i.where("status", "in", ["pending"]),
        i.where("paymentMethod", "==", "manual"),
        i.where("isAutomatic", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const activeWithdrawFiatQueriesESE = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db, "pendingTransWithdrawals"),
        ...(position === "Customer Care Agent" && role.includes("payout")
          ? [i.where("agentId", "==", agentId)]
          : []),
        i.where("product", "==", "payout"),
        i.where("status", "in", ["active"]),
        i.where("paymentMethod", "==", "manual"),
        i.where("isAutomatic", "==", false),
        i.where("isDeferred", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const deferredWithdrawFiatQueriesESE = (agentId, role, position) =>
  unCompleted(role, position, "payout")
    ? [
        i.collection(i.db, "pendingTransWithdrawals"),
        i.where("agentId", "==", null),
        i.where("product", "==", "payout"),
        i.where("isDeferred", "==", true),
        i.where("status", "in", ["active"]),
        i.where("paymentMethod", "==", "manual"),
        i.where("isAutomatic", "==", false),
        i.orderBy("dateCreated", "desc"),
      ]
    : [];

const completedCryptoQueriesESE = completedTransAlt(
  "transCrypto",
  "crypto",
  null
);

const completedOldCryptoTransQueriesESE = completedOldTrans(
  "transactions",
  "crypto",
  null
);

const completedOldGiftcardTransQueriesESE = completedOldTrans(
  "transactions",
  "giftcard",
  null
);

const completedOldWithdrawFiatQueriesESE = completedOldTrans(
  "withdrawalRequest",
  "payout",
  null
);

const completedWithdrawFiatQueriesESE = completedTrans(
  "transWithdrawals",
  "payout",
  "payout"
);

const completedUtilityQueriesESE = completedTrans(
  "transUtilities",
  "utility",
  "payout"
);

const completedFundWalletQueriesESE = completedTransAlt(
  "transDeposits",
  "deposit"
);

const completedTransferFiatQueriesESE = completedTransAlt(
  "transTransferFiat",
  "transfer-fiat"
);

const completedTransferBonusQueriesESE = completedTransAlt(
  "transTransferBonus",
  "transfer-bonus"
);

const completedConvertFiatQueriesESE = completedTransAlt(
  "transConvertFiat",
  "convert-fiat"
);

const useGetUncompletedTrans = (dispatcher, queries) => {
  const { user_details } = useStore().getState().profile || {};
  const { refreshSideInfo } = useStore().getState().util || {};
  const { transInfo } = refreshSideInfo || {};

  const dispatch = useDispatch();

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    dispatch(dispatcher(null));

    let unsub = () => {};
    const db = i.db;
    const agentId = userUid;
    try {
      if (userUid && user_details) {
        const { roles, position } = user_details || {};

        const role = Object.entries(roles || {}).reduce((acc, item) => {
          if (item?.[1]) acc.push(item?.[0]);
          return acc;
        }, []);

        const q = queries(agentId, role, position);

        if (q?.length) {
          unsub = i.onSnapshot(i.query(...q), async (querySnapshot) => {
            const t = await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                try {
                  const { customerId, agentId, label } = doc.data();
                  let f = {};
                  const customerIdRef = customerId || label;
                  if (Boolean(customerIdRef)) {
                    const customerRef = await i.getDoc(
                      i.doc(db, "users", customerIdRef)
                    );

                    let staffRef;
                    if (agentId) {
                      staffRef = await i.getDoc(i.doc(db, "staff", agentId));
                    }

                    if (customerRef.exists()) {
                      const { displayName, email, phoneNumber } =
                        customerRef?.data() || {};

                      let agentObj = {};
                      if (staffRef && staffRef.exists()) {
                        const { displayName, email, phoneNumber } =
                          staffRef?.data() || {};

                        agentObj = {
                          agentDetails: { displayName, email, phoneNumber },
                        };
                      }
                      f = {
                        id: doc.id,
                        ...doc.data(),
                        customerDetails: { displayName, email, phoneNumber },
                        ...agentObj,
                        platform: "esetech",
                      };
                    } else {
                      f = null;
                    }

                    return f;
                  }
                } catch (error) {
                  console.log(error);
                }
              })
            );
            const tResult = filter(t, (i) => !isNull(i));

            dispatch(dispatcher(tResult));
          });
        } else {
          dispatch(dispatcher([]));
        }
      } else {
        dispatch(dispatcher(null));
      }
    } catch (error) {
      console.log(error);
      // dispatch(isLoadingAllTransKap(true));
      // dispatch(isLoadedAllTransKap(false));
      dispatch(dispatcher(null));
    }
    return unsub;
  }, [dispatch, userUid, user_details, transInfo]);
};

const useGetCompletedTrans = (dispatcher, queries, docCollection) => {
  const { user_details } = useStore().getState().profile || {};
  const { transFilterDate } = useStore().getState().util || {};
  const { startDate, endDate } = transFilterDate || {};

  const dispatch = useDispatch();

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    dispatch(dispatcher(null));

    let unsub = () => {};
    const db = i.db;
    const agentId = userUid;
    try {
      if (userUid) {
        const { roles, position } = user_details || {};

        const role = Object.entries(roles || {}).reduce((acc, item) => {
          if (item?.[1]) acc.push(item?.[0]);
          return acc;
        }, []);

        const q = queries(agentId, role, position, startDate, endDate);

        if (q?.length) {
          unsub = i.onSnapshot(i.query(...q), async (querySnapshot) => {
            const t = await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const { customerId, agentId } = doc.data();
                let f = {};
                const customerRef = await i.getDoc(
                  i.doc(db, "users", customerId)
                );

                let staffRef;
                if (agentId) {
                  staffRef = await i.getDoc(i.doc(db, "staff", agentId));
                }

                if (customerRef.exists()) {
                  const { displayName, email, phoneNumber } =
                    customerRef?.data() || {};

                  let agentObj = {};
                  if (staffRef && staffRef.exists()) {
                    const { displayName, email, phoneNumber } =
                      staffRef?.data() || {};

                    agentObj = {
                      agentDetails: { displayName, email, phoneNumber },
                    };
                  }
                  f = {
                    id: doc.id,
                    ...doc.data(),
                    customerDetails: { displayName, email, phoneNumber },
                    ...agentObj,
                    platform: "esetech",
                    docCollection: docCollection || null,
                  };
                } else {
                  f = null;
                }

                return f;
              })
            );
            const tResult = filter(t, (i) => !isNull(i));

            dispatch(dispatcher(tResult));
          });
        } else {
          dispatch(dispatcher([]));
        }
      } else {
        dispatch(dispatcher(null));
      }
    } catch (error) {
      console.log(error);

      dispatch(dispatcher(null));
    }
    return unsub;
  }, [dispatch, userUid, user_details, startDate, endDate]);
};

export const useGetAllTransEse = () => {
  useGetUncompletedTrans(getPendingCryptoTransEse, pendingCryptoQueriesESE);

  useGetUncompletedTrans(
    getPendingWithdrawalFiatTransEse,
    pendingWithdrawFiatQueriesESE
  );

  useGetUncompletedTrans(
    getActiveWithdrawalFiatTransEse,
    activeWithdrawFiatQueriesESE
  );

  useGetUncompletedTrans(
    getDeferredWithdrawalFiatTransEse,
    deferredWithdrawFiatQueriesESE
  );
};

export const useGetAllCompletedTransEse = () => {
  useGetCompletedTrans(getCompletedCryptoTransEse, completedCryptoQueriesESE);

  useGetCompletedTrans(
    getCompletedOldCryptoTransEse,
    completedOldCryptoTransQueriesESE,
    "transactions"
  );

  useGetCompletedTrans(
    getCompletedOldGiftcardTransEse,
    completedOldGiftcardTransQueriesESE,
    "transactions"
  );

  useGetCompletedTrans(
    getCompletedOldWithdrawalFiatTransEse,
    completedOldWithdrawFiatQueriesESE,
    "withdrawalRequest"
  );

  useGetCompletedTrans(
    getCompletedWithdrawalFiatTransEse,
    completedWithdrawFiatQueriesESE
  );

  useGetCompletedTrans(getCompletedUtilityTransEse, completedUtilityQueriesESE);

  useGetCompletedTrans(
    getCompletedFundWalletTransEse,
    completedFundWalletQueriesESE
  );
  useGetCompletedTrans(
    getCompletedTransferFiatTransEse,
    completedTransferFiatQueriesESE
  );
  useGetCompletedTrans(
    getCompletedTransferBonusTransEse,
    completedTransferBonusQueriesESE
  );
  useGetCompletedTrans(
    getCompletedConvertFiatTransEse,
    completedConvertFiatQueriesESE
  );
};

export const useCheckFiatWithdrawalESE = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);
  const [getData, setData] = useState([]);

  const checkTrans = async (values, id) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
    };

    try {
      setSubmitting(true);
      setError(false);
      setData(null);

      const trans = await axiosCall(url(id), valueFormat, true);

      // console.log(trans);
      setSubmitting(false);
      setSubmitted(true);
      setData(trans?.data?.data);
    } catch (error) {
      console.log(error);
      let message;
      switch (error) {
        case "Transaction was not found":
          message = "Transaction record was not found";
          break;
        case "Transaction not found":
          message = "Transaction record was not found";
          break;
        case "Invalid params":
          message = "Invalid Inputs. Check Inputs";
          break;
        case "address does not exist":
          message = "Address does not exist";
          break;
        default:
          message = error?.message || JSON.stringify(error);
      }
      setError(message);
      setSubmitting(false);
      // notification(
      //   "danger",
      //   error?.message === "invalid params"
      //     ? "Invalid Params"
      //     : "An error occured! Try again later.",
      //   "error"
      // );
    }
  };

  return [
    isSubmitting,
    isSubmitted,
    getData,
    setData,
    checkTrans,
    isError,
    setError,
  ];
};

export const useGetLencoWithdrawalESE = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);
  const [getData, setData] = useState([]);

  useEffect(() => {
    const checkTrans = async (values) => {
      const valueFormat = {
        // ...values,
        agentId: auth.currentUser.uid,
        start: "2023-01-03",
        end: "2023-01-03",
      };

      try {
        setSubmitting(true);
        setError(false);
        setData(null);

        const trans = await axiosCall(
          url("get_lenco_withdrawals"),
          valueFormat,
          true
        );

        // console.log(trans?.data);
        setSubmitting(false);
        setSubmitted(true);
        // setData(trans?.data?.data);
      } catch (error) {
        console.log(error);

        setSubmitting(false);
        notification("danger", "An error occured! Try again later.", "error");
      }
    };

    checkTrans();
    return () => {};
  }, []);

  return [isSubmitting, isSubmitted, getData];
};

export const useRevertTransManualEse = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);

  const revertTrans = async (values) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "esetech",
    };

    try {
      setSubmitting(true);
      setError(false);

      const trans = await axiosCall(
        url("revert_trans_manual"),
        valueFormat,
        true
      );

      // console.log(trans?.data?.data, "esetech");
      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Transaction successfully reverted");
    } catch (error) {
      console.log(error);

      setSubmitting(false);
      notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [revertTrans, isSubmitting, isSubmitted];
};

export const useApproveUtilityManualEse = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);

  const approveUtilTrans = async (values) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "esetech",
    };

    try {
      setSubmitting(true);
      setError(false);

      const trans = await axiosCall(
        url("approve_utility_trans_manual"),
        valueFormat,
        true
      );

      // console.log(trans?.data?.data, "esetech");
      setSubmitting(false);
      setSubmitted(true);
      notification("success", "Transaction successfully reverted");
    } catch (error) {
      console.log(error);

      setSubmitting(false);
      notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [approveUtilTrans, isSubmitting, isSubmitted];
};

export const useGetTransHistoryEse = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [getData, setData] = useState([]);
  const [isError, setError] = useState(false);

  const transHistory = async (values, isDone) => {
    const valueFormat = {
      ...values,
      agentId: auth.currentUser.uid,
      platform: "esetech",
    };

    console.log(valueFormat, "valueFormat");

    try {
      setSubmitting(true);
      setError(false);

      const trans = await axiosCall(
        url("get_withdrawal_history"),
        valueFormat,
        true
      );

      setData(trans?.data?.data);
      setSubmitting(false);
      setSubmitted(true);
      isDone(true);
    } catch (error) {
      console.log(error, "asdjasmdjs");
      setData([]);
      setSubmitting(false);
      isDone(false);
      notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [transHistory, getData, isSubmitting, isSubmitted];
};
