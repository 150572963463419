import React, { Fragment, useState } from "react";
import {
  TextField as MuiTextField,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Box,
  MenuItem,
  Select as MuiSelect,
  Chip,
  Autocomplete as MuiAutocomplete,
  Switch as MuiSwitch,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { formatInputNum } from "utils";
import { SimpleIcon } from "./Icon";

export const TextFieldWithIcon = ({
  name,
  type,
  label,
  value,
  handleChange,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        id={name}
        value={value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        }
        label={label}
        type={type}
        size="small"
      />
    </FormControl>
  );
};

export const TextField = ({
  name,
  type = "text",
  label,
  value,
  handleChange,
  error,
  showError,
  touched,
  handleBlur,
  disabled,
  helperText,
  titleText,
  readOnly,
  startAdornment,
  formatInput,
  disableAutoComplete,
  placeholder,
  endAdornment,
  shrink = true,
  size = "medium",
  multiline,
  multilinerows,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {titleText && (
        <div className="mb-3 text-sm text-gray-400">{titleText}</div>
      )}
      <MuiTextField
        multiline={multiline}
        rows={multilinerows}
        fullWidth
        id={name}
        label={label}
        name={name}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        variant="outlined"
        size={size}
        InputLabelProps={{
          ...(shrink && { shrink }),
          classes: {
            root: "dark:!text-white",
          },
        }}
        InputProps={{
          ...(readOnly && { readOnly: true }),
          classes: {
            input: "!text-gray-500 dark:!text-gray-100",
            placeholder: "dark:!text-gray-100",
            root: "!rounded-lg",
            notchedOutline: "dark:!border-gray-500",
          },
          ...(startAdornment && {
            startAdornment: (
              <InputAdornment
                position="start"
                component={() => (
                  // !h-10
                  <div className="mr-1 text-sm text-gray-400 dark:!text-gray-100">
                    {startAdornment}
                  </div>
                )}
              />
            ),
          }),
          ...(endAdornment && {
            endAdornment: (
              <InputAdornment
                position="end"
                classes={{
                  root: "!min-h-[3rem] !h-[3rem] -m-[14px]",
                }}
              >
                {endAdornment}
              </InputAdornment>
            ),
          }),
          ...(type === "password" && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  <SimpleIcon
                    icon={!showPassword ? "VisibilityOff" : "Visibility"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        {...(disabled && { disabled })}
        value={formatInput ? formatInputNum(value) : value}
        {...(handleChange && {
          onChange: (e) =>
            handleChange(
              formatInput ? e.target.value.replace(/[^\w\s]/g, "") : e
            ),
          // onChange: handleChange,
        })}
        {...(handleBlur && { onBlur: handleBlur })}
        {...(error &&
          touched && {
            error: touched && Boolean(error),
            helperText: touched && error,
          })}
        {...(error && showError && { error, helperText })}
        {...(disableAutoComplete && { autoComplete: "off" })}
        {...(placeholder && { placeholder })}
      />
    </>
  );
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
      zIndex: "3000",
      overflow: "hidden",
    },
  },
  classes: {
    list: "dark:!bg-gray-700",
    root: "!z-[1700]",
  },
};

export const Select = ({
  handleChange,
  dropDown,
  dropDownEmptyText,
  inputLabel,
  titleValue,
  subTitleValue,
  defaultValue,
  disableInput,
  disabled,
  valueAlt,
  setValueAlt,
  size = "medium",
  startAdornment,
}) => {
  const [value, setValue] = useState(defaultValue || "");

  const handleInputChange = (e) => {
    const id = e.target.value;
    const findId = dropDown.find((i) => i.id === id);
    setValueAlt
      ? setValueAlt(findId?.[titleValue || "value"])
      : setValue(findId?.[titleValue || "value"]);
    handleChange(findId);
  };

  const id = `select-${inputLabel}`;

  return (
    <FormControl
      fullWidth
      size={size}
      classes={{
        root: "!w-full ",
      }}
    >
      <InputLabel id={id} shrink className="dark:!text-gray-300">
        Select {inputLabel}
      </InputLabel>
      <MuiSelect
        displayEmpty
        labelId={id}
        value={valueAlt || value}
        onChange={handleInputChange}
        input={
          <OutlinedInput
            notched
            label={`Select ${inputLabel}`}
            classes={{
              root: "h-full !rounded-lg dark:!border-gray-500",
              input: "h-full",
              notchedOutline: "dark:!border-gray-500",
            }}
          />
        }
        renderValue={(selected) => {
          return (
            <>
              {selected === "" || !selected || disableInput ? (
                <div className="flex flex-row items-center">
                  {startAdornment && (
                    <InputAdornment
                      position="start"
                      component={() => (
                        <div className="mr-1 text-sm text-gray-400">
                          {startAdornment}
                        </div>
                      )}
                    />
                  )}
                  <span className="text-base text-gray-400">
                    Click to select
                  </span>
                </div>
              ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    label={valueAlt || value}
                    sx={{ height: 26 }}
                    classes={{
                      root: "!text-gray-500 !rounded !text-base dark:!text-gray-200",
                    }}
                  />
                </Box>
              )}
            </>
          );
        }}
        MenuProps={MenuProps}
        // !h-16
        classes={{
          root: "!rounded !z-[3000]",
          paper: "!rounded !z-[3000]",
        }}
      >
        {!disabled && dropDown.length ? (
          dropDown.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              classes={{
                root: "flex flex-col !items-start",
                // list: "dark:!bg-dark-700",
              }}
            >
              <div className="text-sm text-gray-600 dark:!text-white">
                {item?.[titleValue || "value"]}
              </div>
              {subTitleValue && (
                <div className="text-xs lowercase text-gray-400">
                  {item?.[subTitleValue]}
                </div>
              )}
            </MenuItem>
          ))
        ) : (
          <div className="p-2 text-sm text-gray-400">{dropDownEmptyText}</div>
        )}
      </MuiSelect>
    </FormControl>
  );
};

export const Autocomplete = ({
  inputLabel,
  options,
  titleValue,
  handleChange,
  valueAlt,
  setValueAlt,
  loadingText = "No data",
  defaultValue,
}) => {
  const [value, setValue] = useState(valueAlt || []);

  return (
    <MuiAutocomplete
      multiple
      // freeSolo
      loading={options?.length === 0 || valueAlt?.length === 0}
      loadingText={<span className="text-sm">{loadingText}</span>}
      options={options || []}
      getOptionLabel={(option) => option?.[titleValue || "title"]}
      value={valueAlt || value}
      onChange={(event, newValue) => {
        const val = newValue?.[newValue?.length - 1];
        setValueAlt
          ? setValueAlt(val ? [val] : [])
          : setValue(val ? [val] : []);
        handleChange(val ? [val] : []);
      }}
      renderTags={(value, getTagProps) => (
        <>
          {value?.[0]?.[titleValue || "title"] && (
            <Chip
              variant="contained"
              label={value?.[0]?.[titleValue || "title"]}
              {...getTagProps({ index: 0 })}
              classes={{
                root: "!bg-background-main-alt dark:!bg-gray-600",
                label: "dark:!text-gray-200",
                deleteIcon:
                  "dark:!bg-gray-700 dark:!text-gray-100 !rounded-full",
              }}
            />
          )}
        </>
      )}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          autoComplete="off"
          label={inputLabel}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: "!text-gray-500 dark:!text-gray-100",
            },
          }}
          InputProps={{
            ...params.InputProps,
            classes: {
              input: "!text-gray-500 dark:!text-gray-100",
              placeholder: "dark:!text-gray-100",
              root: "!rounded-lg",
              notchedOutline: "dark:!border-gray-500",
            },
          }}

          // size="small"
        />
      )}
      PaperComponent={({ children }) => (
        <div className="!rounded-lg bg-gray-900 !text-gray-200 !shadow-drawer">
          {children}
        </div>
      )}
      classes={{
        // option: "!text-sm",
        input: "!text-gray-500 dark:!text-gray-100",
        placeholder: "dark:!text-gray-100",
        root: "!rounded-lg",
        notchedOutline: "dark:!border-gray-500",
      }}
    />
  );
};

export const Switch = styled((props) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#43a047",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.9,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[600]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#eee" : "#39393D",
    opacity: 1,
    // boxShadow: theme.shadows[4],
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Checkbox Inputs
const CheckIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const CheckIconStyled = styled(CheckIcon)({
  backgroundColor: "#388531",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#388531",
  },
});

function BpCheckbox(props) {
  return (
    <MuiCheckbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckIconStyled />}
      icon={<CheckIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export const CheckboxInputs = ({ checkList, handleChange, defaultValue }) => {
  return (
    <FormGroup>
      {checkList?.map((item) => (
        <FormControlLabel
          key={item?.value}
          classes={{ label: "!text-base dark:!text-white" }}
          control={
            <BpCheckbox
              defaultChecked={defaultValue}
              onChange={(e) => {
                handleChange(e);
              }}
              value={item?.value}
              checked={item?.checked}
              // classes={{
              //   checked: "!text-primary",
              //   root: "dark:!text-primary",
              // }}
            />
          }
          label={item?.label}
        />
      ))}
    </FormGroup>
  );
};

/// Radio Inputs
const RadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const RadioIconStyled = styled(RadioIcon)({
  backgroundColor: "#388531",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#388531",
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="primary"
      checkedIcon={<RadioIconStyled />}
      icon={<RadioIcon />}
      {...props}
    />
  );
}

export const RadioInputs = ({ radioList, defaultValue, handleChange }) => {
  return (
    <RadioGroup
      defaultValue={defaultValue}
      onChange={handleChange}
      aria-labelledby="demo-customized-radios"
      name="customized-radios"
    >
      {radioList?.map((item) => (
        <Fragment key={item.value}>
          <FormControlLabel
            key={item?.value}
            classes={{ label: "!text-base dark:text-white" }}
            value={item?.value}
            control={<BpRadio checked={item?.checked} />}
            label={item?.label}
          />
          {item?.TextComponent && item?.TextComponent}
        </Fragment>
      ))}
    </RadioGroup>
  );
};
