import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_ESETECH_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ESETECH_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ESETECH_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ESETECH_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ESETECH_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ESETECH_FIREBASE_APP_ID,
};

export const firebaseConfigSecondry = {
  apiKey: process.env.REACT_APP_KAAPO_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_KAAPO_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_KAAPO_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_KAAPO_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_KAAPO_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_KAAPO_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_KAAPO_FIREBASE_MEASUREMENT_ID,
};

// console.log({ firebaseConfig }, "esetech firebaseConfig");
// console.log({ firebaseConfigSecondry }, "kaapo firebaseConfig");

const app = initializeApp(firebaseConfig);
const app2 = initializeApp(firebaseConfigSecondry, "secondary");

export const db = getFirestore(app);
export const db2 = getFirestore(app2);

export const storage = getStorage(app);
export const storage2 = getStorage(app2);

export const auth = getAuth(app);
export const auth2 = getAuth(app2);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_ESETECH_FIREBASE_RECAPTCHA_ENTERPRISE_PROVIDER
  ),
  isTokenAutoRefreshEnabled: false,
});

const appCheck2 = initializeAppCheck(app2, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_KAAPO_FIREBASE_RECAPTCHA_ENTERPRISE_PROVIDER
  ),
  isTokenAutoRefreshEnabled: false,
});

export const firebaseAppCheck = appCheck;
export const firebaseAppCheck2 = appCheck2;
