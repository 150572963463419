/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment, useCallback } from "react";
import {
  capitalize,
  flattenDeep,
  isEmpty,
  startCase,
  sum,
  toLower,
} from "lodash";
import { useSelector } from "react-redux";
import {
  Button,
  TextField,
  SimpleIcon,
  DrawerSide,
  Loader,
  SimpleLoader,
} from ".";
import Pulse from "react-reveal/Pulse";

import { summarySingleTrans } from "utils/transactionProps";
import {
  useDrawerDocDetails,
  useFullDrawerUserDetails,
  useFullPageDocDetails,
  useGenerateCode,
  useGetCurrentSideTransTab,
  useGetDrawerTrans,
  useRefreshSideInfo,
  useToggleSideDrawerInfo,
} from "api";
import { useLocation, useNavigate } from "react-router-dom";
import { classNames, copyText, formatInputNum, truncate } from "utils";
import { useAllTrans } from "utils/allTransactionProps";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const listP = [
  "Chief Executive Officer",
  "HR Manager",
  "Customer Care Agent",
  "Supervisor",
];

const stats = {
  failed: "bg-red-400",
  pending: "bg-amber-400",
  success: "bg-emerald-400",
};

const GenerateCode = () => {
  const [genereteCodeParams, setGenereteCodeParams] = useState({});

  const { state } = useLocation();
  const { collection: collectionFrom, type: platform } = state || {};

  const [isSubmittingCode, isSubmittedCode, getTransCode, generateCode] =
    useGenerateCode();

  const handleGetCode = (e) => {
    let val = e.target.value;
    if (e.target.name === "amount") {
      val = val.replace(/[^0-9]/g, "");
    }
    setGenereteCodeParams((prevS) => ({
      ...prevS,
      [e.target.name]: val,
    }));
  };

  return (
    <div className="relative mb-8">
      <Fragment>
        <Accordion
          classes={{
            root: "dark:!bg-gray-800 !rounded-b-lg",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon classes={{ root: "!text-white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{
              root: "!bg-primary !text-white !rounded !h-[45px] !min-h-[45px] dark:!bg-dark-primary",
            }}
          >
            <div className="text-sm"> Generate Transaction Code</div>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: "!p-2",
            }}
          >
            <div className="rounded">
              <div className="mt-2 grid grid-cols-1 gap-4">
                {[
                  { label: "Trans ID", id: "transactionId" },
                  { label: "Status", id: "status" },
                  { label: "Amount", id: "amount" },
                ].map((item, index) => (
                  <div className="col-span-1" key={index}>
                    <TextField
                      name={item.id}
                      value={genereteCodeParams?.[item.id] || ""}
                      label={item.label}
                      placeholder={`Input ${item.label}`}
                      size="small"
                      type="text"
                      handleChange={handleGetCode}
                      disabled={isSubmittedCode}
                      disableAutoComplete

                      // shrink={false}
                    />
                  </div>
                ))}

                {isSubmittedCode && getTransCode && (
                  <div className="col-span-1">
                    <div className="text-sm dark:text-white">
                      Transaction Code
                    </div>
                    <div className="break-all rounded border p-2 dark:border-gray-500 dark:text-gray-100">
                      {getTransCode}
                      <span className="ml-2">
                        <button
                          onClick={() => {
                            copyText(getTransCode, "Copied successfully.");
                          }}
                        >
                          <SimpleIcon
                            fontSize={20}
                            icon="ContentCopy"
                            otherClass="iconShadow-alt"
                          />
                        </button>
                      </span>
                    </div>
                  </div>
                )}
                <div className="col-span-1 text-right">
                  <Button
                    gradient
                    size={"small"}
                    label="Generate Code"
                    handleClick={() => {
                      generateCode({
                        ...genereteCodeParams,
                        collectionFrom,
                        platform,
                      });
                    }}
                    isSubmitting={isSubmittingCode}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Fragment>
    </div>
  );
};

export function SideDrawerInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { user_details } = useSelector((state) => state.profile);
  const { openSideDrawerInfo, currentSideTransTab: getTab } = useSelector(
    (state) => state.util
  );
  const { withdrawalFiatBalance, automatedList, automatedListCount } =
    useSelector((state) => state.transaction);

  const { accountBalance, accountIsLow } = withdrawalFiatBalance || {};

  const [toggleSideDrawerInfo] = useToggleSideDrawerInfo();
  const [setGetTab] = useGetCurrentSideTransTab();
  const [refreshSideInfo] = useRefreshSideInfo();

  const automatedTransListCount = useCallback(() => {
    const obj = Object.values(automatedListCount);
    const anyNull = obj.some((i) => i === null);
    if (anyNull) {
      return null;
    } else {
      return sum(obj);
    }
  }, [automatedListCount])();

  const automatedTransListStatus = useCallback(() => {
    const obj = Object.values(automatedList);
    const anyNull = obj.some((i) => i === null);
    if (anyNull) {
      return null;
    } else {
      const dd = flattenDeep(obj);
      return dd.reduce(
        (acc, item) => {
          const s = item?.otherDetails?.status || "pending";
          acc[s] = acc[s] + 1;
          return acc;
        },
        { failed: 0, pending: 0, success: 0 }
      );
    }
  }, [automatedList])();

  useEffect(() => {
    if (
      ["/chatbox", "/marketing-monitoring", "/transaction-reports"].includes(
        pathname
      )
    ) {
      toggleSideDrawerInfo(false);
    } else {
      toggleSideDrawerInfo(true);
    }
  }, [pathname]);

  const trans = useAllTrans().filter((item) =>
    ["pending", "active", "deferred"].includes(
      toLower(startCase(item.id).split(" ")?.[0])
    )
  );

  const useTransIsLoading = trans
    ?.map((item) => item?.data)
    ?.some((itemS) => itemS === null);

  const useTransCount = trans?.reduce((acc, item) => {
    acc += item?.data?.length || 0;
    return acc;
  }, 0);

  return (
    <Fragment>
      {listP?.includes(user_details?.position) && (
        <div
          className={classNames(
            "fixed w-[27%] duration-500",
            openSideDrawerInfo ? "right-0" : "-right-[27%]"
          )}
        >
          <button
            onClick={() => toggleSideDrawerInfo(!openSideDrawerInfo)}
            className={classNames(
              "fixed top-[80px] z-[1400] flex h-12 w-12 items-center justify-center rounded-tl-lg rounded-bl-lg shadow-dialog duration-500",
              openSideDrawerInfo
                ? "right-[calc(27%-40px)] bg-primary dark:bg-dark-primary"
                : "right-0 bg-primary dark:bg-dark-primary"
            )}
          >
            <SimpleIcon
              icon={
                openSideDrawerInfo
                  ? "KeyboardDoubleArrowLeft"
                  : "KeyboardDoubleArrowRight"
              }
              frontColor={openSideDrawerInfo ? "text-white" : "text-white"}
              fontSize={28}
              otherClass="iconShadow-alt"
            />
          </button>

          <div
            className={classNames("relative ml-8 shadow-drawer duration-500")}
          >
            <div className="relative h-[calc(100vh-80px)] min-h-full overflow-auto bg-box p-2 dark:bg-slate-900">
              <div className="relative min-h-full rounded bg-white p-1 shadow-none dark:bg-gray-800">
                <div className="relative h-full rounded bg-box p-0 shadow-sm dark:bg-gray-800">
                  <div className="flex flex-row justify-start gap-1">
                    {[
                      { name: "Trade Info", id: "tradeInfo", isDisplay: true },
                      {
                        name: "Balances",
                        id: "balances",
                        alert: accountIsLow,
                        alertText: "low",
                        alertClass: "",
                        isDisplay: user_details?.roles?.payout,
                      },
                      {
                        name: "Automated",
                        id: "automated",
                        alert: automatedTransListCount,
                        alertText: automatedTransListCount,
                        alertClass: "h-5 w-5",
                        isDisplay: user_details?.roles?.payout,
                      },
                    ].map((item, index) => (
                      <Fragment key={index}>
                        {item?.isDisplay && (
                          <button
                            className={classNames(
                              "relative flex-1 rounded-tl-lg rounded-tr-lg bg-gray-200 py-3 px-3 text-center text-sm outline-0 dark:bg-gray-900",
                              item?.id === getTab
                                ? "top-[0px] z-[10] border-2 border-b-0 border-primary pt-[12px] dark:border-gray-700 dark:text-white"
                                : "dark:text-gray-500"
                            )}
                            onClick={() => {
                              setGetTab(item?.id);
                            }}
                          >
                            {item?.name}
                            {item?.alert ? (
                              <Pulse forever>
                                <div
                                  className={classNames(
                                    "absolute right-0 top-0 flex items-center justify-center rounded-full bg-red-500 p-1 text-xs text-white",
                                    item?.alertClass
                                  )}
                                >
                                  {item?.alertText}
                                </div>
                              </Pulse>
                            ) : null}
                          </button>
                        )}
                      </Fragment>
                    ))}
                  </div>
                  <div className="relative z-[9] flex h-full w-full flex-col gap-6 border-t border-box bg-box p-3 shadow-sm dark:border-gray-700 dark:bg-gray-900">
                    {getTab === "tradeInfo" && (
                      <>
                        {user_details?.position === "Customer Care Agent" && (
                          <GenerateCode />
                        )}

                        <div className="-mb-4 flex justify-end">
                          <button
                            className="flex items-center justify-center rounded bg-primary p-1"
                            onClick={() => {
                              refreshSideInfo("transInfo");
                            }}
                          >
                            <div className="text-xs text-white">Refresh</div>
                          </button>
                        </div>

                        <>
                          {useTransIsLoading ? (
                            <div className="flex items-center justify-center rounded bg-box p-4 dark:bg-gray-800 dark:text-white">
                              <SimpleLoader />
                            </div>
                          ) : (
                            <>
                              {Boolean(useTransCount) ? (
                                trans?.map((transItem, index) => (
                                  <Fragment key={index}>
                                    {transItem?.data?.length ? (
                                      <div className="relative">
                                        <div className="mb-2 border-b pb-2 text-sm font-semibold text-primary dark:border-slate-500 dark:text-light-primary">
                                          {transItem?.title}
                                        </div>
                                        <div className="flex flex-col gap-y-2">
                                          {transItem?.data?.map((i, index) => {
                                            const item =
                                              transItem?.objectSummary(i);
                                            return (
                                              <div
                                                className="w-full items-start rounded bg-white p-2 dark:bg-gray-800"
                                                key={index}
                                              >
                                                <div className="flex flex-row items-start">
                                                  <div className="mr-2 flex h-5 w-5 flex-row items-center justify-center rounded-full bg-primary text-xs font-semibold text-white">
                                                    {index + 1}
                                                  </div>
                                                  <div>
                                                    {Object.entries(item).map(
                                                      (i, index) => (
                                                        <Fragment key={index}>
                                                          {!i[1]
                                                            ?.disableDisplay && (
                                                            <div className="flex flex-row items-center">
                                                              <div className="text-sm  dark:text-white">
                                                                {capitalize(
                                                                  i[0]
                                                                )}{" "}
                                                                {"-"}
                                                                &nbsp;
                                                              </div>

                                                              <div className="text-sm dark:text-gray-200">
                                                                {typeof i[1]
                                                                  ?.value ===
                                                                "string"
                                                                  ? truncate(
                                                                      i[1]
                                                                        ?.value,
                                                                      23
                                                                    )
                                                                  : i[1]?.value}
                                                              </div>
                                                            </div>
                                                          )}
                                                        </Fragment>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="mt-2 flex justify-end">
                                                  <Button
                                                    color={
                                                      [
                                                        "Claim",
                                                        "Re-claim",
                                                      ].includes(
                                                        transItem?.buttonText
                                                      )
                                                        ? "warning"
                                                        : "success"
                                                    }
                                                    label={
                                                      transItem?.buttonText
                                                    }
                                                    size="small"
                                                    // otherClass="!min-w-0"
                                                    {...(transItem?.handleOnClick && {
                                                      handleClick: () =>
                                                        transItem?.handleOnClick(
                                                          item
                                                        ),
                                                    })}
                                                    {...(transItem?.isSubmitting && {
                                                      isSubmitting:
                                                        transItem?.isSubmitting(
                                                          item
                                                        ),
                                                    })}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    ) : null}
                                  </Fragment>
                                ))
                              ) : (
                                <div className="rounded bg-white p-2 shadow-lg dark:bg-gray-700">
                                  <div className="flex items-center justify-center rounded bg-box p-4 dark:bg-gray-800 dark:text-white">
                                    No Info to display!
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      </>
                    )}

                    {getTab === "balances" && (
                      <>
                        {user_details?.roles?.payout && (
                          <div className="w-full p-0">
                            <div className="mb-2 flex justify-end">
                              <button
                                className="flex items-center justify-center rounded bg-primary p-1"
                                onClick={() => {
                                  refreshSideInfo("accountBalance");
                                }}
                              >
                                <div className="text-xs text-white">
                                  Refresh
                                </div>
                              </button>
                            </div>
                            <div className="w-full rounded border-t bg-white p-3 shadow-md dark:border-gray-700 dark:bg-gray-800">
                              <div className="flex flex-col gap-y-4">
                                {accountBalance ? (
                                  Object.entries(accountBalance)?.map(
                                    (item) => (
                                      <div key={item[0]}>
                                        <div className="mb-1 border-b pb-1 text-sm font-semibold text-primary dark:border-slate-500 dark:text-light-primary">
                                          {capitalize(item[0])} Balance
                                        </div>

                                        <div className="flex flex-col gap-y-2">
                                          {item[1] &&
                                            item[1].map((itemSub) => (
                                              <div
                                                className="flex flex-col items-start justify-between text-sm dark:text-white"
                                                key={itemSub.id}
                                              >
                                                <div className="">
                                                  {capitalize(
                                                    toLower(itemSub?.name)
                                                  )}
                                                </div>
                                                {itemSub?.availableBalance ? (
                                                  <div className="flex flex-row">
                                                    <div className="font-bold">
                                                      ₦
                                                      {formatInputNum(
                                                        itemSub?.availableBalance,
                                                        2
                                                      )}
                                                    </div>
                                                    {itemSub?.isBalanceLow && (
                                                      <Pulse forever>
                                                        <div className="ml-2 rounded-full bg-red-500 p-1 text-xs text-white">
                                                          {"low"}
                                                        </div>
                                                      </Pulse>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className="text-sm text-gray-400">
                                                    Unavailable
                                                  </div>
                                                )}
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div className="flex items-center justify-center rounded bg-box p-4 dark:bg-gray-800 dark:text-white">
                                    <SimpleLoader />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {getTab === "automated" && (
                      <>
                        {user_details?.roles?.payout && (
                          <div>
                            {automatedTransListCount ? (
                              <div className="w-full rounded bg-white p-0 shadow-md dark:border-gray-700 dark:bg-gray-800">
                                <div className="border-b dark:border-gray-700">
                                  <button
                                    className="relative flex w-full flex-row items-center border-none p-4 text-sm outline-none dark:text-white"
                                    onClick={() => {
                                      navigate("/automated-transactions");
                                    }}
                                  >
                                    Automated Payment
                                    <div className="ml-2 flex h-5 w-5 flex-row items-center justify-center rounded-full bg-primary text-white">
                                      {automatedTransListCount}
                                    </div>
                                  </button>
                                </div>
                                <div className="flex flex-col gap-2 p-4">
                                  {automatedTransListStatus &&
                                    Object.entries(
                                      automatedTransListStatus
                                    )?.map((item) => (
                                      <div className="flex text-sm dark:text-white">
                                        <div className="">
                                          {capitalize(item[0])}
                                        </div>

                                        <div
                                          className={classNames(
                                            "ml-2 flex h-4 w-4 flex-row items-center justify-center rounded-full bg-primary text-xs text-white",
                                            stats[item[0]]
                                          )}
                                        >
                                          {item[1]}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ) : (
                              <div className="rounded bg-white p-2 shadow-lg dark:bg-gray-700">
                                <div className="flex items-center justify-center rounded bg-box p-4 dark:bg-gray-800 dark:text-white">
                                  No automated transaction
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export function SideDrawerTrans() {
  const { drawerDocDetails, fullPageDocDetails } = useSelector(
    (state) => state.util || {}
  );

  const { drawerTrans, isDrawerTransLoading: isLoading } = useSelector(
    (state) => state.transaction
  );

  const { collection, docId, type, id, collectionTo } = drawerDocDetails || {};
  const [setDrawerDocDetails] = useDrawerDocDetails();
  const [setFullPageDocDetails] = useFullPageDocDetails();
  const navigate = useNavigate();

  useGetDrawerTrans(collection, docId, type);
  const [fullDrawerUserDetails] = useFullDrawerUserDetails();

  const open = Boolean(drawerDocDetails);
  const setOpen = () => {
    setDrawerDocDetails(false);
  };

  useEffect(() => {
    if (!isLoading && fullPageDocDetails) {
      navigate("/process-transaction", {
        state: fullPageDocDetails,
      });
    }
  }, [fullPageDocDetails, isLoading, navigate]);

  const handleClick = () => {
    setFullPageDocDetails({
      docId,
      collection,
      collectionTo,
      type,
      id,
    });
    setOpen();
  };

  return (
    <DrawerSide {...{ open, setOpen }}>
      {drawerTrans && !isLoading ? (
        <div className="h-auto bg-box p-2 dark:bg-slate-800">
          <div className="h-auto rounded bg-white p-0 shadow-md dark:bg-slate-800">
            <div className="mb-2 flex flex-row items-center justify-between border-b border-slate-200 p-3 dark:border-gray-600">
              <div className="font-semibold dark:text-white">
                View {capitalize(id)} Trade
              </div>
              <Button label="Full Page" gradient handleClick={handleClick} />
            </div>

            {drawerTrans &&
              Object.entries(summarySingleTrans(drawerTrans, id)).map(
                (it, index) => (
                  <Fragment key={index}>
                    {!isEmpty(it[1]) && (
                      <div className="mb-4">
                        <div>
                          <div className="rounded bg-box p-4 dark:bg-gray-800 dark:text-white">
                            <div className="mb-4 border-b font-semibold dark:border-gray-600">
                              {startCase(it[0])}
                            </div>
                            <div className="rounded bg-link-primary/10 dark:bg-gray-700">
                              {Object.entries?.(it[1])?.map((item, index) => (
                                <Fragment key={index}>
                                  {!item[1]?.value ||
                                  item[1]?.disableDisplay ? null : (
                                    <div className="bg-primary/2 mb-2 flex flex-row justify-between rounded-sm border-b border-gray-200 p-4 dark:border-gray-600">
                                      <div className="mr-2 text-left">
                                        {startCase(item[0])}
                                      </div>

                                      {item[0] === "customerID" ? (
                                        <button
                                          className="break-all text-right"
                                          onClick={() => {
                                            fullDrawerUserDetails({
                                              userId: item[1]?.value,
                                              platform: type,
                                            });
                                          }}
                                        >
                                          {item[1]?.value}
                                        </button>
                                      ) : (
                                        <div
                                          className={classNames(
                                            "break-all text-right",
                                            item[1]?.type === "imageComponent"
                                              ? "overflow-auto"
                                              : ""
                                          )}
                                        >
                                          {item[1]?.value}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )
              )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </DrawerSide>
  );
}
