import { Accordion, ImageGallery, SimpleIcon } from "components";
import { capitalize, lowerCase, startCase, toUpper } from "lodash";
import { isNull } from "url/util";
import {
  formatCrypto,
  formatCurrency,
  formatDate,
  formatInputNum,
  formatMoney,
  formatMoney2,
  formatMoneyNoDec,
  symToCur,
  truncate,
} from "utils";
// import { tradeActions } from "shared/constants";

const tradeActions = {
  msg: {
    approved: "Your transaction has been approved.",
    pending:
      "Your transaction will be processed shortly. We will get back to you on its status.",
    active:
      "Your transaction is already in process. We will get back to you shortly.",
    declined:
      "Your transaction was declined. Kindly view the decline upload box for more details.",
  },

  declineReason: {
    "wrong-channel":
      "Your card trade was uploaded through the wrong channel. Kindly submit your trade using the right card type mode.",
    "used-card":
      "The card/code has already been redeemed/used. Kindly reach out to the source of the card for more information.",
    "invalid-card":
      "The card/code is invalid. Kindly reach out to the source of the card for more information.",
  },

  tradeStatus: {
    approved: {
      bg: "bg-green-100/50",
      text: "text-green-500",
      color: "bg-green-500",
      Icon: "CheckCircleOutlineIcon",
    },
    declined: {
      bg: "bg-red-100/50",
      text: "text-red-500",
      color: "bg-red-500",
      Icon: "HighlightOffIcon",
    },
    pending: {
      bg: "bg-yellow-100/50",
      text: "text-yellow-500",
      color: "bg-amber-500",
      Icon: "CheckCircleOutlineIcon",
    },
    active: {
      bg: "bg-sky-100/50",
      text: "text-sky-500",
      color: "bg-cyan-500",
      Icon: "CheckCircleOutlineIcon",
    },
  },
};

const tradeStatus = {
  approved: {
    bg: "bg-emerald-500",
    text: "text-white",
    color: "text-emerald-500",
    icon: "CheckCircleOutlined",
  },
  successful: {
    bg: "bg-emerald-500",
    text: "text-white",
    color: "text-emerald-500",
    icon: "CheckCircleOutlined",
  },
  completed: {
    bg: "bg-emerald-500",
    text: "text-white",
    color: "text-emerald-500",
    icon: "CheckCircleOutlined",
  },
  success: {
    bg: "bg-emerald-500",
    text: "text-white",
    color: "text-emerald-500",
    icon: "CheckCircleOutlined",
  },
  declined: {
    bg: "bg-red-500",
    text: "text-white",
    color: "text-red-500",
    icon: "CancelOutlined",
  },
  failed: {
    bg: "bg-red-500",
    text: "text-white",
    color: "text-red-500",
    icon: "CancelOutlined",
  },
  pending: {
    bg: "bg-amber-500",
    text: "text-white",
    color: "text-amber-500",
    icon: "PendingOutlined",
  },
  active: {
    bg: "bg-cyan-500",
    text: "text-white",
    color: "text-cyan-500",
    icon: "PendingOutlinedIcon",
  },
};

const StatusObj = ({ type }) => {
  const { color, bg, text } = tradeStatus?.[type] || {};
  return (
    <div className={`flex items-center rounded-2xl p-1 px-2 ${bg}`}>
      <span className={`w-full text-center text-sm ${text}`}>{type}</span>
    </div>
  );
};

const StatusIcon = ({ type }) => {
  const { color, bg, icon } = tradeStatus?.[type] || {};
  return (
    <div
      className={`flex h-9 w-9 flex-row items-center justify-center rounded-full ${bg}`}
    >
      <SimpleIcon icon={icon} frontColor={color} fontSize={18} otherClass="" />
    </div>
  );
};

const WalletBalance = ({ balance }) => {
  const cryptos = {
    tetherWallet: { sym: "USDT", type: "crypto", no: 7 },
    binanceWallet: { sym: "BNB", type: "crypto", no: 6 },
    bitcoinWallet: { sym: "BTC", type: "crypto", no: 4 },
    ethereumWallet: { sym: "ETH", type: "crypto", no: 5 },
    tetherTronWallet: { sym: "USDT_TRON", type: "crypto", no: 8 },
    nairaWallet: { sym: "NGN", type: "fiat", no: 1 },
    cedisWallet: { sym: "GHS", type: "fiat", no: 2 },
    cefaWallet: { sym: "XOF", type: "fiat", no: 3 },
  };

  const cList = [
    "bitcoinWallet",
    "ethereumWallet",
    "tetherWallet",
    "tetherTronWallet",
    "binanceWallet",
  ];

  return (
    <ul className="p-2 dark:bg-gray-800">
      {Object.entries(balance)
        ?.sort((b, c) => {
          return cryptos?.[c[0]].no < cryptos?.[b[0]].no ? 1 : -1;
        })
        ?.map((item, index) => (
          <li
            className="flex flex-row items-center justify-between py-2 text-sm dark:text-white"
            key={index}
          >
            <p className="mr-2 text-left">{startCase(item[0])}</p>
            <p className="text-right">
              <Amount
                amount={item[1]}
                walletType={item[0]}
                product={cList.includes(item[0]) ? "crypto" : null}
              />
            </p>
          </li>
        ))}
    </ul>
  );
};

const symValue = {
  nairaWallet: "NGN",
  nairaBonusWallet: "NGN",
  cedisWallet: "GHS",
  cedisBonusWallet: "GHS",
  cefaWallet: "XOF",
  cefaBonusWallet: "XOF",
  bitcoinWallet: "BTC",
  ethereumWallet: "ETH",
  tetherWallet: "USDT",
  tetherTronWallet: "USDT_TRON",
  binanceWallet: "BNB",
};

const symValueAlt = {
  nairaWallet: "Naira",
  cedisWallet: "Cedis",
  cefaWallet: "Cefa",
};

const Amount = ({ amount, walletType, product }) => {
  const amt =
    product === "crypto" ? formatCrypto(amount) : formatInputNum(amount, 2);

  return (
    <span className="">
      {amt} {symValue?.[walletType]}
    </span>
  );
};

export const columns = [
  {
    field: "nos",
    headerName: "NOS",
    width: 50,
  },
  {
    field: "id",
    headerName: "ID",
    width: 110,
    renderCell: (params) => {
      const { platform } = params.row;
      let pt = platform === "kaapo" ? "KPO" : "EST";
      return (
        <>
          {pt}|{params.value.slice(0, 7)}
        </>
      );
    },
  },
  {
    field: "product",
    headerName: "Product",
    width: 80,
    renderCell: (params) => {
      const par = params.row;
      return <span className="">{capitalize(params.value)}</span>;
    },
  },
  {
    field: "productType",
    headerName: "Product Type",
    width: 150,
    renderCell: (params) => {
      return <span className="">{capitalize(params.value)}</span>;
    },
  },
  {
    field: "type",
    headerName: "Type",
    width: 130,
    renderCell: (params) => <span>{capitalize(params.value)}</span>,
  },
  {
    field: "beneficiary",
    headerName: "Beneficiary",
    width: 160,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 190,
    renderCell: (params) => {
      const par = params.row;
      let amt;
      if (par.product === "crypto") {
        amt = (
          <>
            {formatCrypto(params.value)} {par.sym} (
            {formatCurrency(par.dollarAmount, "$")})
          </>
        );
      } else if (par.product === "giftcard") {
        amt = (
          <>
            {formatMoney(params.value, par.sym)} (
            {formatMoneyNoDec(par.amountUSD, par.cardCurrency)})
          </>
        );
      } else {
        amt = <>{formatMoney(params.value, par.sym)}</>;
      }
      return <span className="">{amt}</span>;
    },
  },
  {
    field: "cryptoDollarAmount",
    headerName: "Crypto Dollar Amount",
    width: 100,
    hide: true,
  },
  {
    field: "cryptoSellFrom",
    headerName: "Amount Sold From",
    width: 150,
    hide: true,
  },
  {
    field: "cryptoSellTo",
    headerName: "Amount Sold to",
    width: 150,
    hide: true,
  },
  {
    field: "cryptoBuyFrom",
    headerName: "Amount Bought From",
    width: 150,
    hide: true,
  },
  {
    field: "cryptoBuyTo",
    headerName: "Amount Bought To",
    width: 150,
    hide: true,
  },
  {
    field: "cryptoRate",
    headerName: "Crypto Site Rate",
    width: 150,
    hide: true,
  },

  {
    field: "cardDollarAmount",
    headerName: "Card Dollar Amount",
    width: 150,
    hide: true,
  },
  {
    field: "cardQuantity",
    headerName: "Card Quantity",
    width: 150,
    hide: true,
  },
  {
    field: "beneficiaryMomoName",
    headerName: "Beneficiary Momo Name",
    width: 150,
    hide: true,
  },
  {
    field: "beneficiaryMomoNumber",
    headerName: "Beneficiary Momo Number",
    width: 150,
    hide: true,
  },
  {
    field: "beneficiaryMomoType",
    headerName: "Beneficiary Momo Type",
    width: 150,
    hide: true,
  },

  {
    field: "beneficiaryName",
    headerName: "Beneficiary Name",
    width: 150,
    hide: true,
  },

  {
    field: "beneficiaryBank",
    headerName: "Beneficiary Bank",
    width: 150,
    hide: true,
  },

  {
    field: "beneficiaryAccountNo",
    headerName: "Beneficiary AccountNo",
    width: 150,
    hide: true,
  },

  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: (params) => {
      const par = params.value;
      return <StatusObj type={par} />;
    },
  },
  {
    field: "date",
    headerName: "Date",
    width: 180,
    renderCell: (params) => (
      <span className="">{formatDate(params.value)}</span>
    ),
  },
  {
    field: "fiatType",
    headerName: "Fiat Type",
    width: 100,
  },

  {
    field: "platform",
    headerName: "Platform",
    width: 100,
    renderCell: (params) => {
      return <>{capitalize(params.value)}</>;
    },
  },
  {
    field: "paymentMethod",
    headerName: "Payment Method",
    width: 120,
  },
  {
    field: "deviceType",
    headerName: "Device Type",
    width: 120,
  },
];

export const columns_alt = [
  {
    field: "nos",
    headerName: "NOS",
    width: 50,
  },
  {
    field: "id",
    headerName: "ID",
    width: 110,
    renderCell: (params) => {
      const { platform } = params.row;
      let pt = platform === "kaapo" ? "KPO" : "EST";
      return (
        <>
          {pt}|{params.value.slice(0, 7)}
        </>
      );
    },
  },
  {
    field: "product",
    headerName: "Product",
    width: 80,
    renderCell: (params) => {
      return <span className="">{capitalize(params.value)}</span>;
    },
  },
  {
    field: "productType",
    headerName: "Product Type",
    width: 150,
    renderCell: (params) => {
      return <span className="">{capitalize(params.value)}</span>;
    },
  },
  {
    field: "type",
    headerName: "Type",
    width: 130,
    renderCell: (params) => <span>{capitalize(params.value)}</span>,
  },

  {
    field: "amount",
    headerName: "Amount",
    width: 190,
    renderCell: (params) => {
      const par = params.row;
      let amt;
      if (par.product === "crypto") {
        amt = (
          <>
            {formatCrypto(params.value)} {par.sym} (
            {formatCurrency(par.dollarAmount, "$")})
          </>
        );
      } else if (par.product === "giftcard") {
        amt = (
          <>
            {formatMoney(params.value, par.sym)} (
            {formatMoneyNoDec(par.amountUSD, par.cardCurrency)})
          </>
        );
      } else {
        amt = <>{formatMoney(params.value, par.sym)}</>;
      }
      return <span className="">{amt}</span>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: (params) => {
      const par = params.value;
      return <StatusObj type={par} />;
    },
  },
  {
    field: "date",
    headerName: "Date",
    width: 180,
    renderCell: (params) => (
      <span className="">{formatDate(params.value)}</span>
    ),
  },
  {
    field: "fiatType",
    headerName: "Fiat Type",
    width: 100,
  },

  {
    field: "platform",
    headerName: "Platform",
    width: 100,
    renderCell: (params) => {
      return <>{capitalize(params.value)}</>;
    },
  },
  {
    field: "paymentMethod",
    headerName: "Payment Method",
    width: 100,
  },
];

export function withdrawFiat() {
  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      status,
      dateCreated,
      customerDetails,
      platform,
      amount,
      amountToDebit,
      defaultCurrency,
      walletToDebit,
      withdrawDetails,
      paymentMethod,
      deviceType,
    } = item || {};

    const {
      accountDetails,
      accountName: oldColAccountName,
      bankAccountNo: oldColBankAccountNo,
      bankName: oldColBankName,
    } = withdrawDetails || {};

    const {
      momoName,
      momoNumber,
      momoType,
      accountName,
      bankName,
      bankAccountNo,
      accountNumber,
    } = accountDetails || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: "withdrawal",
          amount: amount || amountToDebit,
          sym: symValue?.[item.walletToDebit],
          beneficiary: customerDetails?.displayName,
          beneficiaryMomoName: momoName,
          beneficiaryMomoNumber: momoNumber,
          beneficiaryMomoType: momoType,
          beneficiaryName: accountName || oldColAccountName,
          beneficiaryBank: bankName || oldColBankName,
          beneficiaryAccountNo:
            accountNumber || bankAccountNo || oldColBankAccountNo,

          date: dateCreated,
          platform: platform,
          paymentMethod,
          deviceType,
          status,
          fiatType: defaultCurrency || symValueAlt?.[walletToDebit],
          collection: item?.docCollection || "transWithdrawals",
        }
      : {};
  };

  const drawerSummary = (item) => {
    const {
      product,
      productType,
      dateCreated,
      customerDetails,
      amount,
      amountToDebit,
      status,
    } = item || {};
    return item
      ? {
          id: {
            value: item.id,
            disableDisplay: true,
          },
          customerId: {
            value: item.customerId,
            disableDisplay: true,
          },
          product: {
            value: `${capitalize(product)} / ${productType?.split("-")[1]}`,
          },
          amount: {
            value: (
              <span className="">
                <Amount
                  {...{
                    amount: amount || amountToDebit,
                    walletType: item?.walletToDebit,
                  }}
                />
              </span>
            ),
          },
          customer: {
            value: customerDetails?.displayName,
            disableDisplay: true,
          },
          status: {
            value: status,
            disableDisplay: true,
          },
          date: {
            value: formatDate(dateCreated),
            disableDisplay: true,
          },
        }
      : {};
  };

  return {
    rows,
    drawerSummary,
  };
}

export function crypto() {
  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      transaction,
      status,
      dateCreated,
      customerDetails,
      walletToCredit,
      walletToDebit,
      platform,
      deviceType,
      defaultCurrency,
    } = item || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: transaction?.tradeType,
          amount: item?.transaction?.crypto,
          dollarAmount: item?.transaction?.usd,
          cryptoDollarAmount: formatCurrency(item?.transaction?.usd, "$"),

          cryptoSellFrom:
            transaction?.tradeType === "sell"
              ? `${transaction?.crypto} ${symValue?.[walletToDebit]}`
              : "",
          cryptoSellTo:
            transaction?.tradeType === "sell"
              ? `${transaction?.fiat || transaction?.ngn} ${
                  symValue?.[walletToCredit]
                }`
              : "",
          cryptoBuyFrom:
            transaction?.tradeType === "buy"
              ? `${transaction?.fiat || transaction?.ngn} ${
                  symValue?.[walletToDebit]
                }`
              : "",
          cryptoBuyTo:
            transaction?.tradeType === "buy"
              ? `${transaction?.crypto} ${symValue?.[walletToCredit]}`
              : "",

          cryptoRate: ["buy", "sell"]?.includes(transaction?.tradeType)
            ? `${formatCurrency(transaction?.fiatRate || transaction?.rate)}/$`
            : "",
          sym: transaction?.sym,
          beneficiary: customerDetails?.displayName,
          date: dateCreated,
          platform: platform,
          deviceType,
          status,
          fiatType:
            defaultCurrency ||
            (transaction?.tradeType === "sell"
              ? symValueAlt?.[walletToCredit]
              : symValueAlt?.[walletToDebit]),
          collection: item?.docCollection || "transCrypto",
        }
      : {};
  };

  const drawerSummary = (item) => {
    console.log(item, "summary");
    const {
      assetType,
      amount,
      created_at,
      timestamp,
      customerDetails,
      status,
    } = item || {};
    return item
      ? {
          id: {
            value: item.id,
            disableDisplay: true,
          },
          customerId: {
            value: item.label,
            disableDisplay: true,
          },
          product: {
            value: `crypto / ${assetType}`,
          },
          amount: {
            value: (
              <span className="">
                {formatCrypto(amount)} {assetType}
              </span>
            ),
          },
          customer: {
            value: customerDetails?.displayName,
            disableDisplay: true,
          },
          status: {
            value: status,
            disableDisplay: true,
          },
          date: {
            value: formatDate(parseInt(timestamp) || created_at),
          },
        }
      : {};
  };

  return { rows, drawerSummary };
}

export function giftcards() {
  const amtUSD = (qty) =>
    qty
      ? Object?.entries(qty)?.reduce((acc, item) => {
          acc += item[0] * item[1].count;
          return acc;
        }, 0)
      : 0;

  const cardQuantity = (qty) =>
    qty
      ? Object?.entries(qty)?.reduce((acc, item, arr, ind) => {
          acc += `${item[0]} * ${item[1].count} @ ${item[1].price} ||`;
          return acc;
        }, "")
      : "";

  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      transaction,
      status,
      dateCreated,
      customerDetails,
      defaultCurrency,
      walletToCredit,
      platform,
      deviceType,
    } = item || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: transaction?.tradeType,
          amountUSD: amtUSD(item?.transaction?.qty),
          cardDollarAmount: formatCurrency(
            amtUSD(item?.transaction?.qty),
            symToCur(transaction.country)
          ),
          cardQuantity: cardQuantity(item?.transaction?.qty),
          cardCurrency: symToCur(transaction.country),
          amount: transaction?.total,
          sym: symValue?.[item.walletToCredit],
          beneficiary: customerDetails?.displayName,
          date: dateCreated,
          platform: platform,
          deviceType,
          status,
          fiatType: defaultCurrency || symValueAlt?.[walletToCredit],
          collection: item?.docCollection || "transGiftcards",
        }
      : {};
  };

  const drawerSummary = (item) => {
    const {
      product,
      productType,
      dateCreated,
      transaction,
      customerDetails,
      isDeferred,
      status,
    } = item || {};
    return item
      ? {
          id: {
            value: item.id,
            disableDisplay: true,
          },
          customerId: {
            value: item.customerId,
            disableDisplay: true,
          },
          product: {
            value: `${capitalize(product)} / ${productType}`,
          },
          amount: {
            value: (
              <span className="">
                <Amount
                  {...{
                    amount: transaction?.total,
                    walletType: item?.walletToCredit,
                  }}
                />{" "}
                ({formatMoneyNoDec(amtUSD(transaction?.qty), "$")})
              </span>
            ),
          },
          customer: {
            value: customerDetails?.displayName,
            disableDisplay: true,
          },
          status: {
            value: status ? (
              <span className={tradeStatus[status]?.color}>
                {capitalize(status)}
                {isDeferred ? " / Deferred" : null}
              </span>
            ) : (
              isNull
            ),
            disableDisplay: true,
          },
          date: {
            value: formatDate(dateCreated),
            disableDisplay: true,
          },
        }
      : {};
  };

  return {
    rows,
    drawerSummary,
  };
}

export function fundFiat() {
  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      status,
      dateCreated,
      customerDetails,
      platform,
      settlementAmount,
      amount,
    } = item || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: "fund-wallet",
          amount: settlementAmount,
          sym: symValue?.[item.walletToCredit],
          beneficiary: customerDetails?.displayName,
          date: dateCreated,
          platform: platform,
          status,
          collection: "transDeposits",
        }
      : {};
  };

  return { rows };
}

export function transferFiat(current_user) {
  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      status,
      dateCreated,
      customerDetails,
      platform,
      amount,
    } = item || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: "transfer-fiat",
          amount,
          sym: symValue?.[item.walletToDebit],
          beneficiary: customerDetails?.displayName,
          date: dateCreated,
          platform: platform,
          status,
          collection: "transTransferFiat",
        }
      : {};
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          charges: {
            value: (
              <Amount
                {...{
                  amount: 0,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          ...(current_user === item.customerId && {
            transferTo: {
              value: item?.transferDetails?.userTo?.email,
            },
          }),

          ...(current_user === item.transferCustomerId && {
            transferFrom: {
              value: item?.transferDetails?.userFrom?.email,
            },
          }),

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          ...(current_user === item.customerId && {
            walletsBalancesBefore: {
              value: <WalletBalance balance={item?.walletsBalance} />,
              type: "dropdown",
            },
          }),
        }
      : {};
  };

  return { rows, summary };
}

export function utilities() {
  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      status,
      dateCreated,
      customerDetails,
      platform,
      amount,
    } = item || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: "utility",
          amount,
          sym: symValue?.[item.walletToDebit],
          beneficiary: customerDetails?.displayName,
          date: dateCreated,
          platform: platform,
          status,
          collection: "transUtilities",
        }
      : {};
  };

  const drawerSummary = (item) => {
    const {
      product,
      productType,
      dateCreated,
      customerDetails,
      status,
      amount,
    } = item || {};
    return item
      ? {
          id: {
            value: item.id,
            disableDisplay: true,
          },
          customerId: {
            value: item.customerId,
            disableDisplay: true,
          },
          product: {
            value: `${capitalize(product)} / ${productType}`,
          },
          amount: {
            value: <span className="">{formatMoney(amount)}</span>,
          },
          customer: {
            value: customerDetails?.displayName,
            disableDisplay: true,
          },
          status: {
            value: status,
            disableDisplay: true,
          },
          date: {
            value: formatDate(dateCreated),
            disableDisplay: true,
          },
        }
      : {};
  };

  return {
    rows,
    drawerSummary,
  };
}

export function transferBonus() {
  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      status,
      dateCreated,
      customerDetails,
      platform,
      amount,
    } = item || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: "bonus",
          amount,
          sym: symValue?.[item.walletToDebit],
          beneficiary: customerDetails?.displayName,
          date: dateCreated,
          platform: platform,
          status,
          collection: "transTransferBonus",
        }
      : {};
  };

  return { rows };
}

export function convertFiat() {
  const rows = (item, index) => {
    const {
      id,
      product,
      productType,
      status,
      amount,
      dateCreated,
      customerDetails,
      platform,
    } = item || {};

    return item
      ? {
          nos: index + 1,
          id,
          product: product,
          productType: productType,
          type: "convert",
          amount,
          sym: symValue?.[item.walletToDebit],
          beneficiary: customerDetails?.displayName,
          date: dateCreated,
          platform: platform,
          status,
          collection: "transConvertFiat",
        }
      : {};
  };

  return { rows };
}

export function PendingWithdrawFiat() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        const par = params.row;
        return (
          <Amount
            {...{ amount: params.value, walletType: par.walletToDebit }}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="font-bold">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="font-bold">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="font-bold">{capitalize(params.value)}</span>;
      },
    },

    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return (
      arr
        // ?.sort((b, c) => c.dateCreated - b.dateCreated)
        ?.map((item, index) => ({
          nos: index + 1,
          id: item.id,
          idNos: `KAP|${toUpper(item.id.slice(0, 7))}`,
          status: item.status,
          asset: "Fiat",
          assetType: item.productType?.split("-")[1],
          action: item.productType,
          amount: item.totalAmount,
          date: item.dateCreated,
          walletToDebit: item.walletToDebit,
        }))
    );
  };

  const summary = (item) => {
    const { withdrawDetails, totalAmount, dateCreated } = item || {};
    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          charges: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.charges,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          amount: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.amount,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          beneficiaryMomoName: {
            value: <>{withdrawDetails?.accountDetails?.momoName}</>,
            type: "text",
          },

          beneficiaryMomoNumber: {
            value: withdrawDetails?.accountDetails?.momoNumber,
          },
          beneficiaryMomoType: {
            value: withdrawDetails?.accountDetails?.momoType,
          },
          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function checkTransactions() {
  const listAutomatedFiatWithdrawalData = () => {
    const columns = [
      {
        id: "status",
        title: "Status",
      },
      {
        id: "amount",
        title: "Amount",
      },
      {
        id: "customer",
        title: "Customer",
      },
      { id: "transactionDetails", title: "Details" },
      { id: "dateCreated", title: "Date" },
      { id: "paymentProvider", title: "Payment Provider" },
    ];

    const rows = (item) => {
      const { otherDetails: itemOther, userDetails, amount } = item || {};

      return {
        status: <StatusObj type={itemOther?.status} />,
        amount: (
          <Amount
            amount={itemOther?.amount || amount}
            walletType="nairaWallet"
          />
        ),
        customer: userDetails?.displayName,
        transactionDetails: `${itemOther?.transationDetails}`,
        dateCreated: formatDate(item?.dateCreated),
        paymentProvider: item?.fiatTransferProvider,
      };
    };
    return { columns, rows };
  };

  const checkFiatWithdrawalData = () => {
    const columns = [
      {
        id: "status",
        title: "Status",
      },
      {
        id: "amount",
        title: "Amount",
      },
      { id: "accountName", title: "Account Name" },
      { id: "bankName", title: "Bank Name" },
      { id: "initiatedAt", title: "Date" },
    ];

    const rows = (item) => {
      return {
        status: <StatusObj type={item?.status} />,
        amount: <Amount amount={item?.amount} walletType="nairaWallet" />,
        accountName: item?.accountName,
        bankName: item?.bankName,
        initiatedAt: formatDate(item?.initiatedAt || item?.completedAt),
      };
    };
    return { columns, rows };
  };

  const checkCryptoTransData = () => {
    const columns = [
      {
        id: "status",
        title: "Status",
      },
      {
        id: "amount",
        title: "Amount",
      },
      { id: "dollarAmount", title: "Dollar Amount" },
      { id: "confirmation", title: "Confirmation" },
      { id: "createdAt", title: "Date" },
    ];
    const rows = (item) => {
      return {
        status: <StatusObj type={lowerCase(item?.status)} />,
        amount: (
          <>
            {formatCrypto(item?.amount)} {item?.assetType}
          </>
        ),
        dollarAmount: <>{formatCurrency(item?.dollarAmount, "$", 2)}</>,
        confirmation: item?.confirmation,
        createdAt: formatDate(item?.createdAt),
      };
    };
    return { columns, rows };
  };

  const checkFiatTransData = () => {
    const columns = [
      {
        id: "status",
        title: "Status",
      },
      {
        id: "settledAmount",
        title: "Amount Received",
      },
      { id: "fee", title: "Fee" },
      { id: "transactionAmount", title: "Total Amount" },
      { id: "datetime", title: "Date" },
    ];
    const rows = (item) => {
      return {
        status: <StatusObj type={lowerCase(item?.status)} />,
        settledAmount: <>{formatCurrency(item?.settledAmount, "₦", 2)}</>,
        fee: <>{formatCurrency(item?.fee, "₦", 2)}</>,
        transactionAmount: (
          <>{formatCurrency(item?.transactionAmount, "₦", 2)}</>
        ),

        datetime: formatDate(item?.datetime),
      };
    };
    return { columns, rows };
  };

  const checkUtilityTransData = () => {
    const columns = [
      {
        id: "status",
        title: "Status",
      },
      {
        id: "amount",
        title: "Amount",
      },
      { id: "type", title: "Product" },
      { id: "beneficiary", title: "Beneficiary" },
      { id: "dateCreated", title: "Date" },
    ];
    const rows = (item) => {
      return {
        status: <StatusObj type={lowerCase(item?.status)} />,
        amount: <>{formatCurrency(item?.settledAmount, "₦", 2)}</>,
        type: item.type,
        beneficiary: item?.beneficiary,
        dateCreated: formatDate(item?.dateCreated),
      };
    };
    return { columns, rows };
  };

  const checkCryptoAddress = () => {
    const columns = [
      {
        id: "address",
        title: "Address",
      },
      { id: "assetType", title: "Asset Type" },
      { id: "chain", title: "Chain" },
      { id: "network", title: "Network" },
      { id: "createdAt", title: "Date" },
    ];
    const rows = (item) => {
      return {
        address: item?.address,
        assetType: item?.assetType,
        chain: item?.chain,
        network: item?.network,
        createdAt: formatDate(item?.createdAt),
      };
    };
    return { columns, rows };
  };

  const checkFiatAccount = () => {
    const columns = [
      {
        id: "accountName",
        title: "Account Name",
      },
      {
        id: "accountNumber",
        title: "Account Number",
      },
      {
        id: "bankName",
        title: "Bank Name",
      },

      { id: "createdAt", title: "Date" },
    ];
    const rows = (item) => {
      return {
        accountName: item?.accountName,
        accountNumber: item?.accountNumber,
        bankName: item?.bankName,
        createdAt: formatDate(item?.createdAt),
      };
    };
    return { columns, rows };
  };

  const checkCryptoWithdrawal = () => {
    const columns = [
      {
        id: "status",
        title: "Status",
      },
      {
        id: "amount",
        title: "Amount",
      },
      { id: "dollarAmount", title: "Dollar Amount" },
      { id: "sentAddress", title: "Sent Address" },
      { id: "created_at", title: "Date" },
    ];
    const rows = (item) => {
      return {
        status: <StatusObj type={lowerCase(item?.status)} />,
        amount: (
          <>
            {formatCrypto(Math.abs(item?.amount))} {item?.assetType}
          </>
        ),
        dollarAmount: (
          <>{formatCurrency(Math.abs(item?.dollarAmount), "$", 2)}</>
        ),
        sentAddress: item?.sentAddress,
        created_at: formatDate(item?.created_at),
      };
    };
    return { columns, rows };
  };

  return {
    listAutomatedFiatWithdrawalData,
    checkFiatWithdrawalData,
    checkCryptoTransData,
    checkFiatTransData,
    checkUtilityTransData,
    checkCryptoAddress,
    checkFiatAccount,
    checkCryptoWithdrawal,
  };
}

export const summarySingleTrans = (item, id) => {
  const {
    product,
    productType,
    transaction,
    hash,
    assetType,
    status,
    customerDetails,
    agentDetails,
    dateCreated,
    comment,
    amount,
    totalAmountDeposit,
    amountBought,
    amountSold,
    beneficiary,
    transactionUpload,
    transactionStarted,
    transactionEnded,
    isDeferred,
    deferReason,
    deferredByAgent,
    deferredTime,
    deferAgentDetails,
    declineConfirmationUpload,
    declineReason,
    convertDetails,
    withdrawDetails,
    transferDetails,
    amountToCredit,
    amountToDebit,
    walletToCredit,
    walletToDebit,
    walletsBalance,
    platform,
  } = item || {};
  const { type, tradeType, total, crypto, fiat, country, qty } =
    transaction || {};

  const { rate, rateTo, sym, symTo } = convertDetails || {};
  const { amount: withdrawDetailsAmount, accountDetails } =
    withdrawDetails || {};

  const {
    accountName: oldColAccountName,
    bankAccountNo: oldColBankAccountNo,
    bankName: oldColBankName,
  } = withdrawDetails || {};

  const { charges } = withdrawDetailsAmount || {};
  const {
    momoName,
    momoNumber,
    momoType,
    accountName,
    bankName,
    bankAccountNo,
    accountNumber,
  } = accountDetails || {};

  const totalAmount =
    crypto || amount || total || totalAmountDeposit || amountToDebit;

  // const { msg, declineReason: reason, tradeStatus } = tradeActions;

  const useWalletToCredit = ["sell", "receive"];
  // const useWalletToDebit = ["convert-fiat"];
  const assetTypeWallet = {
    BTC: "bitcoinWallet",
    ETH: "ethereumWallet",
    BNB: "binanceWallet",
    BSC: "binanceWallet",
    USDT: "tetherWallet",
    USDT_TRON: "tetherTronWallet",
  };

  return item
    ? {
        tradeDetails: {
          status: {
            value: <StatusObj type={status} />,
            type: "component",
          },
          product: { value: capitalize(product), type: "text" },
          productType: { value: capitalize(productType), type: "text" },
          cardType: { value: capitalize(type), type: "text" },
          transactionType: { value: capitalize(tradeType), type: "text" },

          [`${product === "transfer-bonus" ? "bonus" : "transaction"}Total`]: {
            value:
              product !== "transfer-bonus" ? (
                <Amount
                  {...{
                    amount: totalAmount,
                    product: hash ? "crypto" : item?.product,
                    walletType: hash
                      ? assetTypeWallet?.[assetType]
                      : useWalletToCredit.includes(tradeType) ||
                        product === "deposit"
                      ? item?.walletToCredit
                      : item?.walletToDebit,
                  }}
                />
              ) : (
                <Amount
                  amount={totalAmount}
                  walletType={
                    productType.includes("receive")
                      ? item?.walletToCredit
                      : item?.walletToDebit
                  }
                  product={item?.product}
                />
              ),
          },

          totalAmountOnTransaction: {
            value: transferDetails?.transactionDetails?.amountOnTransaction ? (
              <Amount
                amount={
                  transferDetails?.transactionDetails?.amountOnTransaction
                }
                walletType={
                  productType.includes("receive")
                    ? item?.walletToCredit
                    : item?.walletToDebit
                }
              />
            ) : null,
          },

          totalAmountFunded: {
            value: item?.settlementAmount
              ? formatMoney(item?.settlementAmount)
              : null,
          },
          convertFiatFrom: {
            value:
              product === "convert-fiat" ? (
                <>{formatMoney(amountToDebit, symValue?.[walletToDebit])}</>
              ) : null,
            type: "text",
          },
          convertFiatTo: {
            value:
              product === "convert-fiat" ? (
                <>{formatMoney(amountToCredit, symValue?.[walletToCredit])}</>
              ) : null,
            type: "text",
          },
          convertRate: {
            value: rate ? `${rate} ${sym} -  ${rateTo} ${symTo}` : null,
            type: "text",
          },

          fiatWithdrawalCharges: {
            value: charges ? (
              <Amount
                {...{
                  amount: charges,
                  walletType: useWalletToCredit.includes(product)
                    ? item?.walletToCredit
                    : item?.walletToDebit,
                }}
              />
            ) : null,
          },

          cryptoDollarAmount: {
            value:
              product === "crypto" ? (
                <span>{formatCurrency(transaction?.usd, "$")}</span>
              ) : null,
          },

          cryptoSellFrom: {
            value:
              transaction?.tradeType === "sell" && product === "crypto" ? (
                <Amount
                  {...{
                    amount: transaction?.crypto,
                    walletType: item?.walletToDebit,
                    product: "crypto",
                  }}
                />
              ) : null,
          },

          cryptoSellTo: {
            value:
              transaction?.tradeType === "sell" && product === "crypto" ? (
                <Amount
                  {...{
                    amount: transaction?.fiat || transaction?.ngn,
                    walletType: item?.walletToCredit,
                  }}
                />
              ) : null,
          },

          cryptoBuyFrom: {
            value:
              transaction?.tradeType === "buy" && product === "crypto" ? (
                <Amount
                  {...{
                    amount: transaction?.fiat || transaction?.ngn,
                    walletType: item?.walletToCredit,
                  }}
                />
              ) : null,
          },

          cryptoBuyTo: {
            value:
              transaction?.tradeType === "buy" && product === "crypto" ? (
                <Amount
                  {...{
                    amount: transaction?.crypto,
                    walletType: item?.walletToDebit,
                    product: "crypto",
                  }}
                />
              ) : null,
          },

          cryptoRate: {
            value:
              ["buy", "sell"]?.includes(transaction?.tradeType) &&
              product === "crypto" ? (
                <span>
                  {formatCurrency(transaction?.fiatRate || transaction?.rate)}/$
                </span>
              ) : null,
          },

          cryptoConvertFrom: {
            value:
              transaction?.tradeType === "convert" && product === "crypto" ? (
                <Amount
                  {...{
                    amount: transaction?.crypto,
                    walletType: item?.walletToDebit,
                    product: "crypto",
                  }}
                />
              ) : null,
          },

          cryptoConvertTo: {
            value:
              transaction?.tradeType === "convert" && product === "crypto" ? (
                <Amount
                  {...{
                    amount: transaction?.usdt,
                    walletType: item?.walletToCredit,
                  }}
                />
              ) : null,
          },

          [`crypto${capitalize(transaction?.tradeType)}Fees`]: {
            value: transaction?.feesUsd
              ? `${formatCrypto(transaction?.fees)} ${
                  transaction?.sym
                } (${formatInputNum(transaction?.feesUsd, 2)} USD)`
              : null,

            type: "text",
          },

          bonusDescription: {
            value: item?.bonusDescription,
            type: "text",
          },

          recipientAddress: {
            value: transaction?.recipientAddress,
            type: "text",
          },

          fundWalletCharges: {
            value: item?.charges ? formatMoney(item?.charges) : null,
          },

          beneficiaryMomoName: {
            value: momoName,
            type: "text",
          },

          beneficiaryMomoNumber: {
            value: momoNumber,
            type: "text",
          },
          beneficiaryMomoType: {
            value: momoType,
            type: "text",
          },

          beneficiaryName: {
            value: accountName || oldColAccountName,
            type: "text",
          },
          beneficiaryBank: {
            value: bankName || oldColBankName,
            type: "text",
          },
          beneficiaryAccountNo: {
            value: accountNumber || bankAccountNo || oldColBankAccountNo,
            type: "text",
          },

          totalAmountSold: {
            value: item?.amountSold ? (
              <Amount
                {...{
                  amount: item?.amountSold,
                  walletType:
                    item?.[
                      item.productType === "convert-airtime"
                        ? "walletToCredit"
                        : "walletToDebit"
                    ],
                }}
              />
            ) : null,
            type: "text",
          },

          provider: {
            value: item?.provider ? startCase(item?.provider) : null,
            type: "text",
          },

          productPlan: {
            value: item?.productPlan ? startCase(item?.productPlan) : null,
            type: "text",
          },

          beneficiary: {
            value: beneficiary,
            type: "text",
          },

          amountTransferred: {
            value: amountSold ? (
              <Amount
                {...{
                  amount: amountSold,
                  walletType: item?.walletToCredit,
                }}
              />
            ) : null,
          },
          amountToSell: {
            value: amountBought ? (
              <Amount
                {...{
                  amount: amountBought,
                  walletType: item?.walletToCredit,
                }}
              />
            ) : null,
          },

          cardQty: {
            value: qty ? (
              <>
                {Object?.entries(qty)?.map((i) => (
                  <p key={i[0]}>
                    {symToCur(country)}
                    {i[0]} x {i[1].count} @
                    <Amount
                      {...{
                        amount: i[1].price,
                        walletType: item?.walletToCredit,
                      }}
                    />
                    ({i[1].rate}/{symToCur(country)})
                  </p>
                ))}
              </>
            ) : null,
            type: "component",
          },

          cardCurrency: { value: country, type: "text" },

          additionalComment: {
            value: (
              <div className="text-right">
                {!Boolean(comment)
                  ? "No additional comment from customer"
                  : comment}
              </div>
            ),
            type: "text",
          },

          beforeWalletBalances: {
            value: walletsBalance ? (
              <Accordion
                title="Balances"
                content={<WalletBalance balance={walletsBalance} />}
              />
            ) : null,
          },

          transactionDate: { value: formatDate(dateCreated), type: "text" },

          transactionRef: {
            value: item?.transId,
            type: "text",
            clickable: true,
          },

          transactionID: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          platform: {
            value: platform,
            type: "text",
          },
        },

        uploadedFiles: transactionUpload
          ? {
              transactionUpload: {
                value: transactionUpload ? (
                  <ImageGallery
                    images={transactionUpload}
                    type={["view", "download"]}
                    className="flex p-3 space-x-4"
                  />
                ) : null,
                type: "imageComponent",
              },
            }
          : {},

        declinedTransactionDetails: declineReason
          ? {
              declineReason: { value: declineReason, type: "text" },
              declineConfirmationUpload: {
                value: declineConfirmationUpload ? (
                  <ImageGallery
                    images={declineConfirmationUpload}
                    type={["view"]}
                    className="flex p-3 space-x-4"
                  />
                ) : null,
                type: "imageComponent",
              },
            }
          : {},

        deferredDetails: deferAgentDetails
          ? {
              deferReason: {
                value: startCase(deferReason),
              },
              deferredByStaffName: {
                value: deferAgentDetails?.displayName,
                type: "text",
              },
              deferredByStaffEmail: {
                value: deferAgentDetails?.email,
                type: "text",
              },
              deferredTime: {
                value: deferredTime ? formatDate(dateCreated) : null,
                type: "text",
              },
            }
          : {},

        customerDetails: {
          customerID: {
            value: item?.customerId || item?.label,
            type: "type",
          },
          customerName: {
            value: customerDetails?.displayName,
            type: "type",
          },
          customerEmail: {
            value: customerDetails?.email,
            type: "type",
          },
          customerContact: {
            value: `+${customerDetails?.phoneNumber}`,
            type: "type",
          },
        },

        processingDetails: transactionStarted
          ? {
              staffName: {
                value: agentDetails?.displayName,
                type: "text",
              },
              staffEmail: {
                value: agentDetails?.email,
                type: "text",
              },
              processingStarted: {
                value: transactionStarted
                  ? formatDate(transactionStarted)
                  : "---",
                type: "text",
              },
              processingEnded: {
                value: transactionEnded ? formatDate(transactionEnded) : "---",
                type: "text",
              },
            }
          : {},
      }
    : {};
};

export const pendingNotifTransObj = (item) => ({
  notification: `You have a new ${capitalize(item?.product)} transaction`,
  title: `${capitalize(item?.product)} Transaction`,
  product: capitalize(item.product),
  productType: item.productType,
  amount: (
    <Amount
      {...{
        amount: item.amount,
        walletType: ["giftcard"].includes(item.product)
          ? item?.walletToCredit
          : item?.walletToDebit,
      }}
    />
  ),
  beneficiary: item?.customerDetails?.displayName,
  dateCreated: formatDate(item.dateCreated),
  otherValues: item?.otherValues,
  // display: roles?.giftcard,
});
