import imageCompression from "browser-image-compression";
import * as i from "./lib";
import axios from "axios";
import fileDownload from "js-file-download";

import UAParser from "ua-parser-js";
import DeviceDetector from "device-detector-js";
import { notification } from "utils";
import { appCheck } from "./actions/util";
const deviceDetector = new DeviceDetector();
const parser = new UAParser();

const auth = i.getAuth();

const url = (endpoint) => `${i.baseURL_ET}/${endpoint}`;

export const getStoragePlatform = (platform) => {
  switch (platform) {
    case "esetech":
      return i.storage;
    case "kaapo":
      return i.storage2;
    default:
      throw new Error("Invalid platform");
  }
};

export const uploadImage = async (
  images,
  storagePath,
  fileName,
  platform,
  maxSizeMB = 0.5
) => {
  const getStorage = getStoragePlatform(platform);

  const uploadedImgProm = await images.map(async (image) => {
    const compressImgOption = {
      maxSizeMB,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    try {
      const file = await imageCompression(image.file, compressImgOption);
      const storageRef = i.ref(
        getStorage,
        `${storagePath}/${fileName || file.name}`
      );
      const snapshot = await i.uploadBytes(storageRef, file);
      const downloadURL = await i.getDownloadURL(snapshot.ref);

      return downloadURL;
    } catch (error) {
      //console.log(error);
    }
  });

  return await Promise.all(uploadedImgProm);
};

export const downloadUploadedImage = async (url) => {
  try {
    // Uri.parse(await i.ref.getDownloadURL()).toString();

    const refUrl = i.ref(i.storage2, url);

    const metadata = await i.getMetadata(refUrl);
    const { name } = metadata;

    const res = await i.axios.get(url, {
      responseType: "blob",
    });

    fileDownload(res.data, name);
  } catch (error) {
    notification("danger", "Cannot download filekkk");
    //console.log(error, "hvkjhgvjhkk");
  }
};

export const options = (transData, url, platform, appCheckToken) => {
  const authPlatform = platform === "kaapo" ? i.auth2 : auth;

  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: authPlatform?.currentUser?.accessToken,
      "X-Firebase-AppCheck": appCheckToken,
    },
    data: {
      uid: auth.currentUser.uid,
      transData: { ...transData, staffId: auth.currentUser.uid },
    },
    url,
  };
};

const optionsAlt = (transData, url, appCheckToken) => ({
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Firebase-AppCheck": appCheckToken,
  },
  url,
  data: {
    transData,
  },
});

export const axiosCall = (url, transData, useAuth, platform = "esetech") => {
  return new Promise(async (resolve, reject) => {
    const appCheckTokenResponse = await appCheck(platform);

    try {
      const option = useAuth
        ? options(transData, url, platform, appCheckTokenResponse)
        : optionsAlt(transData, url, appCheckTokenResponse);

      const res = await axios(option);
      return resolve(res);
    } catch (error) {
      let err = error;
      if (error.response) {
        err = error.response?.data?.error;
      }
      return reject(err);
    }
  });
};

export const verifyOTP = async (values) => {
  const valueFormat = {
    customerId: auth.currentUser.uid,
    email: values?.newEmail || auth.currentUser.email,
    username: values?.displayName,
  };

  const opt = options(valueFormat, url("auth/verify_email_otp"));

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const checkOTP = async (values) => {
  const valueFormat = {
    customerId: auth.currentUser.uid,
    email: values?.newEmail || auth.currentUser.email,
    code: values?.code,
  };

  const opt = options(valueFormat, url("auth/check_email_otp"));

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(
        error.response && error.response?.data?.error === "Otp do not match"
          ? "Otp do not match"
          : error
      );
    }
  });
};

export const updateEmail = async (values) => {
  const valueFormat = {
    customerId: auth.currentUser.uid,
    email: values?.newEmail,
  };

  const opt = options(valueFormat, url("auth/update_email"));

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const updateAcct = async (values) => {
  const valueFormat = {
    customerId: auth.currentUser.uid,
    ...values,
  };

  const opt = options(valueFormat, url("profile/update_withdrawal_account"));

  return new Promise(async (resolve, reject) => {
    try {
      const res = await i.axios(opt);
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getDevice = () => {
  const userAgent = parser.getResult().ua;
  return deviceDetector.parse(userAgent);
};
