import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PageContainer } from "shared";
import {
  Button,
  EnterPIN,
  ImageUpload,
  Loader,
  SectionContent,
  TextField,
} from "components";
import {
  useGetSingleTrans,
  useCompleteTrans,
  useDeferTrans,
  useFullPageDocDetails,
  useGiftcardUtils,
} from "api";
import { summarySingleTrans } from "utils/transactionProps";
import { capitalize, startCase } from "lodash";
import { AppBar, Checkbox } from "@mui/material";
import { classNames } from "utils";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

export default function ProcessTransaction() {
  const [getStatus, setGetStatus] = useState(null);
  const [getDeclineReason, setGetDeclineReason] = useState(null);
  const [getDeferReason, setGetDeferReason] = useState(null);
  const [reconfirm, setReconfirm] = useState(null);
  const [getApproveCode, setGetApproveCode] = useState(null);
  const [images, setImages] = useState([]);

  const navigate = useNavigate();

  const { state } = useLocation();
  const { collection, collectionTo, docId, type, id } = state || {};

  useGetSingleTrans(collection, docId, type);
  useGiftcardUtils();

  const [isSubmitting, isSubmitted, completeTrade] = useCompleteTrans();
  const [isSubmittingDefer, isSubmittedDefer, deferTrade] = useDeferTrans();

  const [setFullPageDocDetails] = useFullPageDocDetails();

  const { singleTrans, isSingleTransLoading: isLoading } = useSelector(
    (state) => state.transaction
  );
  const {
    giftcardDeclineReasons: declineReason,
    giftcardDeferReasons: deferReason,
  } = useSelector((state) => state.util);

  const { user_details } = useSelector((state) => state.profile);
  // const { position } = user_details || {};
  const position = "Customer Care Agent";

  const handleImageChange = (imageList) => {
    setImages(imageList);
  };

  useEffect(() => {
    setGetStatus(null);
  }, [state, singleTrans, isLoading]);

  useEffect(() => {
    setGetDeclineReason(null);
    setGetDeferReason(null);
    setImages([]);
    setReconfirm(null);
    setGetApproveCode("");
  }, [getStatus?.id]);

  useEffect(() => {
    setImages([]);
    setReconfirm(null);
    setGetApproveCode("");
  }, [getDeclineReason]);

  useEffect(() => {
    setReconfirm(null);
    setGetApproveCode("");
  }, [images]);

  useEffect(() => {
    const scroll = window.scrollTo(5000, 5000);
    return scroll;
  }, [
    getStatus?.id,
    getDeclineReason,
    getDeferReason,
    images?.length,
    reconfirm,
    getApproveCode?.length > 20,
  ]);

  useEffect(() => {
    if (isSubmitted || isSubmittedDefer) {
      navigate("/transaction");
    }
  }, [isSubmitted, isSubmittedDefer]);

  useEffect(() => {
    return () => {
      setFullPageDocDetails(null);
    };
  }, []);

  const handleProcessTrans = () => {
    const {
      customerId,
      id: transactionId,
      imageUID,
      amount,
      status: transStatus,
    } = singleTrans;

    if (["approved", "declined"].includes(getStatus?.id)) {
      const value = {
        customerId,
        transactionId,
        amount,
        transCode: Boolean(getApproveCode) ? getApproveCode : null,
        status: getStatus?.id,
        declineReason: getDeclineReason,
        collectionFrom: collection,
        collectionTo,
        platform: type,
      };

      completeTrade(value, images, imageUID);
    }

    if (getStatus?.id === "deferred") {
      const value = {
        transactionId,
        amount,
        deferReason: getDeferReason,
        status: transStatus,
        collectionFrom: collection,
        platform: type,
      };
      deferTrade(value);
    }
  };

  return (
    <PageContainer>
      {singleTrans &&
      !isLoading &&
      Boolean(declineReason) &&
      Boolean(deferReason) ? (
        <div className="rounded bg-white p-3 dark:bg-gray-900">
          <AppBar
            sx={{ position: "sticky" }}
            classes={{
              root: "h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow !sticky flex flex-row justify-center p-6 !z-[900] dark:from-dark_bg_gradient_from dark:to-dark_bg_gradient_from",
            }}
          >
            <div className="text-lg font-semibold">
              Process {capitalize(id)} Trade
            </div>
          </AppBar>

          {singleTrans &&
            Object.entries(summarySingleTrans(singleTrans, id)).map((it) => (
              <>
                {!isEmpty(it[1]) && (
                  <div className="mb-4">
                    <SectionContent>
                      <div className="rounded bg-box p-6 shadow-card-box dark:bg-gray-800">
                        <div className="mb-4 border-b pb-2 font-semibold dark:border-gray-600 dark:text-white">
                          {startCase(it[0])}
                        </div>
                        <div className="rounded bg-link-primary/10">
                          {Object.entries?.(it[1])?.map((item) => (
                            <>
                              {!item[1]?.value ||
                              item[1]?.disableDisplay ? null : (
                                <div className="bg-primary/2 mb-2 flex flex-row justify-between rounded-sm border-b border-gray-200 p-4 dark:border-gray-500">
                                  <div className="dark:text-white">
                                    {startCase(item[0])}
                                  </div>
                                  <div className="dark:text-white">
                                    {item[1]?.value}
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </SectionContent>
                  </div>
                )}
              </>
            ))}

          {singleTrans?.status === "active" &&
            position === "Customer Care Agent" && (
              <div className="mb-4 grid grid-cols-3 gap-x-4 rounded">
                {[
                  {
                    title: "Approve Transaction",
                    bgcolor: "!bg-emerald-400",
                    color: "success",
                    id: "approved",
                    text: "approve",
                    textColor: "!text-white",
                    textColor_alt: "!text-emerald-500",
                  },
                  {
                    title: "Decline Transaction",
                    bgcolor: "!bg-red-400",
                    color: "error",
                    id: "declined",
                    text: "decline",
                    textColor: "!text-white",
                    textColor_alt: "!text-red-500",
                  },
                  {
                    title: "Defer Transaction",
                    bgcolor: "!bg-cyan-400",
                    color: "info",
                    id: "deferred",
                    text: "defer",
                    textColor: "!text-white",
                    textColor_alt: "!text-cyan-500",
                  },
                ].map((item, index) => (
                  <div className="col-span-1" key={index}>
                    <SectionContent>
                      <div className="flex w-full flex-row justify-between rounded bg-box p-2 shadow-card-box dark:bg-gray-800">
                        <div
                          className={classNames(
                            "m-0 flex flex-1 flex-row items-center justify-between rounded border bg-link-primary/5 p-2 dark:border-gray-500 dark:bg-gray-700",
                            item.id === getStatus?.id
                              ? "rounded border-4 border-primary dark:border-light-primary"
                              : ""
                          )}
                        >
                          <div className="dark:text-white">{item.title}</div>
                          <div className="ml-2">
                            <Button
                              label={capitalize(item.text)}
                              // color={item.color}
                              // variant="outlined"
                              size={"small"}
                              handleClick={() => {
                                setGetStatus(item);
                              }}
                              otherClass={`${item.bgcolor} ${item.textColor}`}
                            />
                          </div>
                        </div>
                      </div>
                    </SectionContent>
                  </div>
                ))}
              </div>
            )}

          {getStatus?.id === "declined" && (
            <>
              <div className="mb-4">
                <SectionContent>
                  <div className="rounded bg-box p-2 shadow-card-box dark:bg-gray-800">
                    <div className="mb-6 border-b pb-2 font-semibold dark:border-gray-500 dark:text-white">
                      Select Reason for Declining Transaction
                    </div>
                    <div className="flex flex-row justify-between">
                      <div>
                        {Object.entries(declineReason).map((item) => (
                          <div className="flex flex-row items-start p-2">
                            <Checkbox
                              checked={getDeclineReason === item[0]}
                              size="small"
                              onChange={() => {
                                setGetDeclineReason(item[0]);
                              }}
                              classes={{ root: "!p-0 dark:!text-white" }}
                            />
                            <div className="ml-2 flex flex-col">
                              <div className="dark:text-white">
                                {startCase(item[0])}
                              </div>
                              <div className="text-sm dark:text-gray-400">
                                {item[1]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </SectionContent>
              </div>

              {getDeclineReason && id === "giftcard" && (
                <div className="mb-4">
                  <SectionContent>
                    <div className="rounded bg-box p-2 shadow-card-box dark:bg-gray-800">
                      <div className="mb-6 border-b pb-2 font-semibold dark:border-gray-500 dark:text-white">
                        Upload Proof for Declining Transaction
                      </div>
                      <div className="flex flex-row justify-between">
                        <ImageUpload
                          images={images}
                          handleChange={handleImageChange}
                          className="flex flex-wrap rounded bg-white p-2 dark:bg-gray-900"
                        />
                      </div>
                    </div>
                  </SectionContent>
                </div>
              )}
            </>
          )}

          {getStatus?.id === "approved" && (
            <div className="mb-4">
              <SectionContent>
                <div className="rounded bg-box p-2 shadow-card-box dark:bg-gray-800">
                  <div className="mb-6 border-b pb-2 font-semibold dark:border-gray-500 dark:text-white">
                    Input Transaction Code
                  </div>
                  <TextField
                    size="medium"
                    label={"Code"}
                    type="text"
                    placeholder="Input Transaction Code"
                    disableAutoComplete
                    handleChange={(e) => setGetApproveCode(e.target.value)}
                  />
                </div>
              </SectionContent>
            </div>
          )}

          {getStatus?.id === "deferred" && (
            <>
              <div className="mb-4">
                <SectionContent>
                  <div className="rounded bg-box p-2 shadow-card-box dark:bg-gray-800">
                    <div className="mb-6 border-b pb-2 font-semibold dark:border-gray-500 dark:text-white">
                      Select Reason for Deferring Transaction
                    </div>
                    <div className="flex flex-row justify-between ">
                      <div>
                        {Object.entries(deferReason).map((item) => (
                          <div className="flex flex-row items-start p-2">
                            <Checkbox
                              checked={getDeferReason === item[0]}
                              size="small"
                              onChange={() => {
                                setGetDeferReason(item[0]);
                              }}
                              classes={{ root: "!p-0 dark:!text-white" }}
                            />
                            <div className="ml-2 flex flex-col">
                              <div className="dark:text-white">
                                {startCase(item[0])}
                              </div>
                              <div className="text-sm dark:text-gray-400">
                                {item[1]}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </SectionContent>
              </div>
            </>
          )}

          {getStatus?.id && (
            <>
              {(getDeclineReason && (images?.length || id !== "giftcard")) ||
              getApproveCode.length > 20 ||
              getDeferReason ? (
                <>
                  <div className="mb-4">
                    <SectionContent>
                      <div className="rounded bg-box p-2 shadow-card-box dark:bg-gray-800">
                        <div className="mb-6 border-b pb-2 font-semibold dark:border-gray-500 dark:text-white">
                          Reconfirm Transaction Action
                        </div>
                        <div className="dark:text-white">
                          <div>
                            Kindly reconfirm that you want to{" "}
                            <span
                              className={`font-bold uppercase text-red-500 ${getStatus?.textColor_alt}`}
                            >
                              {getStatus?.text}
                            </span>{" "}
                            this transaction
                          </div>
                          <div className="mt-4 flex flex-row items-center space-x-2">
                            <Button
                              gradient
                              label="Yes"
                              handleClick={() => setReconfirm(true)}
                            />
                            <Button
                              color="default"
                              label="No"
                              handleClick={() => {
                                setReconfirm(false);
                                setGetStatus(null);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </SectionContent>
                  </div>

                  {reconfirm ? (
                    <div className="mb-4">
                      <SectionContent>
                        <div className="rounded bg-box p-2 shadow-card-box dark:bg-gray-800">
                          <div className="mb-6 border-b pb-2 font-semibold dark:border-gray-500 dark:text-white">
                            Complete Transaction Action
                          </div>
                          <div className="rounded bg-white p-4 dark:bg-gray-800">
                            <EnterPIN
                              {...{
                                processSubmit: handleProcessTrans,
                                btnText: `${
                                  getStatus?.id === "deferred"
                                    ? "Defer"
                                    : "Complete"
                                } Transaction`,
                                isSubmitting: isSubmitting || isSubmittingDefer,
                              }}
                            />
                          </div>
                        </div>
                      </SectionContent>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      ) : (
        <div className="relative flex h-40 w-full items-center justify-center rounded bg-box p-6 shadow-card-box dark:bg-gray-900">
          <Loader />
        </div>
      )}
    </PageContainer>
  );
}
