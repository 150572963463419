import React, { useState, useEffect } from "react";
import {
  BackdropSection,
  Button,
  SectionContent,
  SimpleIcon,
} from "components";
import { PageContainer } from "shared";
import { useFiatRate, useFiatRateUtils, useUpdateFiatRate } from "api";
import { useSelector } from "react-redux";
import { toUpper } from "lodash";
import { notification, toFixed } from "utils";

export default function Fiat() {
  const [getData, setGetData] = useState(null);
  const [platform, setPlatform] = useState(null);

  const { fiatRate } = useSelector((state) => state.rate);
  useFiatRate();

  const [isUpdating, isUpdated, updateFac] = useUpdateFiatRate();

  useEffect(() => {
    if (isUpdated) {
      setGetData(null);
      setPlatform(null);
    }
  }, [isUpdated]);

  const handleUpdateValue = () => {
    // console.log(getData, platform);
    updateFac(getData, platform);
  };

  const handleChange = (value, item, id) => {
    if (getData !== null && id !== Object?.keys(getData || {})?.[0]) {
      notification("warning", "Save to contine");
      return;
    } else {
      setGetData(value);
      setPlatform(item[0]);
    }
  };

  const keys = {
    NGN: 1,
    GHS: 2,
    XOF: 3,
  };

  const platformKeys = {
    esetech: 1,
    kaapo: 2,
  };

  return (
    <PageContainer>
      {fiatRate ? (
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(fiatRate)
            ?.sort((a, b) => platformKeys[a?.[0]] - platformKeys[b?.[0]])
            ?.map((item) => (
              <div className="col-span-1">
                <SectionContent>
                  <div className="flex flex-row items-center justify-between rounded bg-white p-2 dark:bg-slate-800">
                    <div className="flex flex-row items-center justify-start">
                      <div className="flex h-8 w-8 flex-row items-center justify-center rounded bg-slate-50 dark:bg-slate-600">
                        <SimpleIcon icon="ViewAgenda" fontSize={18} />
                      </div>
                      <div>
                        <div className="ml-2">
                          <p className="font-bold  dark:text-gray-50">
                            Fiat Rate - {toUpper(item?.[0])}
                          </p>
                          <p className="truncate text-sm text-gray-500 dark:text-gray-200">
                            Edit fiat rate
                          </p>
                        </div>
                      </div>
                    </div>

                    <Button
                      label="Save"
                      gradient
                      disabled={!getData}
                      handleClick={handleUpdateValue}
                      isSubmitting={isUpdating && platform === item?.[0]}
                    />
                  </div>
                  <div className="mt-2">
                    <div className="flex flex-row gap-2">
                      <ul className="divide-y divide-gray-200 rounded bg-box p-2 dark:divide-gray-700 dark:bg-gray-800">
                        <li className="py-3 sm:py-4">
                          <div className="flex items-start justify-between">
                            <div className="min-w-0 flex-1">
                              <p className="truncate font-medium dark:text-white">
                                Rates
                              </p>
                              <div className="mt-2">
                                <p className="truncate py-3 text-gray-500 dark:text-gray-300">
                                  {""}
                                </p>
                              </div>
                            </div>
                            {Object.entries(item[1])
                              ?.sort((a, b) => keys[a?.[0]] - keys[b?.[0]])
                              ?.map((itemR) => (
                                <div
                                  className="min-w-0 flex-1 pr-6 text-right"
                                  key={itemR?.[0]}
                                >
                                  <p className="truncate font-medium dark:text-gray-300">
                                    {itemR?.[0]}
                                  </p>
                                  <div className="mt-2">
                                    <p className="flex flex-row items-end truncate py-3 text-gray-500">
                                      <input
                                        className="w-full border-none bg-transparent text-right outline-none dark:text-white"
                                        defaultValue={toFixed(
                                          `${itemR?.[1]?.rate}`,
                                          4
                                        )}
                                        disabled={itemR?.[0] === "NGN"}
                                        placeholder="0.00"
                                        onChange={(e) =>
                                          handleChange(
                                            {
                                              [`${itemR?.[0]}.rate`]:
                                                parseFloat(e.target.value),
                                            },
                                            item,
                                            `${itemR?.[0]}.rate`
                                          )
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </SectionContent>
              </div>
            ))}
        </div>
      ) : (
        <div className="relative flex h-44 w-full flex-row items-center justify-center rounded bg-box p-4 dark:bg-gray-900">
          <div className="w-full p-4 text-center">
            <BackdropSection open={true} />
          </div>
        </div>
      )}
    </PageContainer>
  );
}
