import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Alert as DeleteRate,
  Dialog,
  IconButton,
  PopOver,
  PopoverAlt,
  SimpleIcon,
  TableData,
} from "components";
import { useUpdateGiftcardRate } from "api";
import AddRate from "../components/AddRate";
import { UpdateRate } from "../components/UpdateRate";
import { has } from "lodash";
import { notification } from "utils";

export default function RatePopup({
  currentRate,
  giftcardFac,
  openPopupRate,
  setOpenPopupRate,
  defaultCurrency,
  setDefaultCurrency,
}) {
  const [openPopup, setOpenPopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [currentContent, setCurrentContent] = useState({});
  const [currentContentHover, setCurrentContentHover] = useState(null);

  const { rate, qty } = currentContent;
  const [rateValue, setRateValue] = useState(rate || "");

  const [isUpdating, isUpdated, updateRate] = useUpdateGiftcardRate();

  const {
    profile: { user_details },
  } = useSelector((state) => state);
  const { giftcards } = useSelector((state) => state.giftcard);

  const { position } = user_details;
  const positionList = ["Chief Executive Officer"];
  const isEnabledRole = !positionList.includes(position);

  const { id, currency, type, title } = currentRate || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rateFactors = (qty, rate) => {
    const { exchangeRate, vatRate, commission } = giftcardFac || {};
    const { NGN, GHS, XOF } = exchangeRate || {};

    const eRate = NGN;
    let defaultCurr = NGN;

    switch (defaultCurrency) {
      case "NGN":
        defaultCurr = 1;
        break;
      case "GHS":
        defaultCurr = GHS;
        break;
      case "XOF":
        defaultCurr = XOF;
        break;

      default:
        defaultCurr = NGN;
        break;
    }

    const rateSum = NGN * parseFloat(rate);
    const rateBeforeVat = rateSum - commission;
    const vat = commission * (vatRate / 100);
    const actualRate = rateBeforeVat - vat;
    const totalRate = Math.round(actualRate);
    const websiteRate = totalRate * parseInt(qty, 10) * defaultCurr;

    return [
      rateSum,
      rateBeforeVat,
      eRate,
      vat,
      actualRate,
      totalRate,
      websiteRate,
    ];
  };

  const rateList =
    giftcards && giftcards.find((i) => i.id === id)?.rate?.[currency]?.[type];

  // console.log(rateList, "rateList");

  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    { field: "qty", headerName: "Qty", width: 120 },
    {
      field: "rate",
      headerName: "Rate",
      width: 120,
      renderCell: (params) => (
        <>
          {console.log(params, "params")}
          {params.value === "Load" ? (
            <CircularProgress color="success" size={20} />
          ) : (
            params.value
          )}
        </>
      ),
    },
    {
      field: "rateSum",
      headerName: "Rate Sum ($)",
      width: 120,
      hide: isEnabledRole,
    },
    {
      field: "exchangeRate",
      headerName: "Exchange rate ($)",
      width: 150,
      hide: isEnabledRole,
    },
    {
      field: "rateBeforeVat",
      headerName: "Rate Before VAT",
      width: 150,
      hide: isEnabledRole,
    },
    { field: "vat", headerName: "VAT", width: 120, hide: isEnabledRole },
    {
      field: "actualRate",
      headerName: "Actual Rate ($)",
      width: 150,
      hide: isEnabledRole,
    },
    {
      field: "totalRate",
      headerName: "Total Rate ($)",
      width: 150,
    },
    {
      field: "websiteRate",
      headerName: `Website Rate (${defaultCurrency})`,
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 80,
      renderCell: (params) => (
        <>
          {currentContentHover !== parseInt(params.id) ? (
            <div className="flex flex-row justify-between">
              <button className="p-2 rounded-full hover:bg-gray-500/25">
                <SimpleIcon
                  icon="RemoveCircle"
                  frontColor="text-red-500"
                  fontSize="small"
                />
              </button>
            </div>
          ) : null}
        </>
      ),
    },
  ];

  const rows = useCallback(() => {
    const c = rateList?.[qty];

    return rateList
      ? Object.entries(rateList)?.map((item, index) => {
          const qty = item[0];
          const rate = item[1];
          const [
            rateSum,
            rateBeforeVat,
            exchangeRate,
            vat,
            actualRate,
            totalRate,
            websiteRate,
          ] = rateFactors(qty, rate) || {};

          const toFixed = (n) => parseFloat(n).toFixed(2);

          return {
            nos: index + 1,
            id: qty,
            qty,
            rate:
              currentContent?.qty === qty
                ? c !== parseFloat(rateValue) && isUpdating
                  ? "Load"
                  : Number.isNaN(parseFloat(rateValue))
                  ? ""
                  : toFixed(rateValue)
                : toFixed(rate),
            rateSum: toFixed(rateSum),
            exchangeRate: toFixed(exchangeRate),
            rateBeforeVat: toFixed(rateBeforeVat),
            vat: toFixed(vat),
            actualRate: toFixed(actualRate),
            totalRate: toFixed(totalRate),
            websiteRate: toFixed(websiteRate),
            actions: "",
          };
        })
      : [];
  }, [
    rateList,
    qty,
    rateFactors,
    currentContent?.qty,
    rateValue,
    isUpdating,
  ])();

  const handleUpdateRate = (val) => {
    const key = `rate.${currency}.${type}.${currentContent?.qty}`;
    updateRate({ key: [key], val: parseFloat(val) }, id, "update_rate");
  };

  const handleDeleteRate = () => {
    const key = `rate.${currency}.${type}.${currentContent?.qty}`;

    updateRate({ key: [key] }, id, "delete_rate", () => setOpenAlert(false));
  };

  const handleAddRate = (q, v, callback) => {
    const key = `rate.${currency}.${type}.${q}`;
    // console.log(rateList, currentRate, q, v, "sjddsf");
    if (has(rateList, q)) {
      notification("danger", "Rate already exist", "error");
      return;
    }
    updateRate({ key: [key], val: parseFloat(v) }, id, "add_rate", callback);
  };

  return (
    <Dialog
      onClose={() => {
        setOpenPopup(false);
        setOpenAlert(false);
      }}
      fullScreen
      open={openPopupRate}
      setOpen={setOpenPopupRate}
      title={`${type} (${title} - ${currency})`}
      AppBarButtonComponent={() => (
        <div className="mr-4">
          <PopoverAlt
            PopoverHandlerComponent={() => (
              <div
                className="mr-2 flex w-[80px] flex-row items-center rounded-lg border border-gray-200 bg-gray-100 px-2 py-2 text-center dark:border-gray-600 dark:bg-gray-800 dark:text-white"
                onClick={() => {}}
              >
                <div className="flex flex-row justify-between w-full font-semibold text-gray-500 dark:text-white">
                  {defaultCurrency}
                  <SimpleIcon icon="KeyboardArrowDown" />
                </div>
              </div>
            )}
            PopoverContentComponent={(close) => (
              <ul className="w-[80px] divide-y divide-gray-200 p-0 dark:divide-gray-600 dark:bg-slate-800">
                {["NGN", "GHS", "XOF"].map((type) => (
                  <li className="w-full py-0">
                    <button
                      className="w-full p-2 text-left rounded bg-box dark:bg-gray-900 dark:text-white"
                      onClick={() => {
                        setDefaultCurrency(type);
                        close();
                      }}
                    >
                      {type}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          />
        </div>
      )}
    >
      <div className="p-4">
        <TableData
          rows={rows}
          columns={columns}
          hideFooter
          noPageSize
          useField
          onRowClick={(id, field) => {
            if (field === "actions") {
              setOpenAlert(!openAlert);
            } else {
              setOpenPopup(true);
            }
            setCurrentContent({ qty: id, rate: rateList[id] });
          }}
          onRowHover={(id) => {
            setCurrentContentHover(id);
          }}
        />
        <AddRate {...{ isUpdating, handleAddRate, setCurrentContent }} />
      </div>

      <UpdateRate
        {...{ rateValue, setRateValue }}
        openModal={openPopup}
        setOpenModal={setOpenPopup}
        content={currentContent}
        isSubmitting={isUpdating}
        handleSubmit={handleUpdateRate}
      />

      <DeleteRate
        {...{ openAlert, setOpenAlert }}
        title="Are you sure you want to delete this rate?"
        isSubmitting={isUpdating}
        handleSubmit={handleDeleteRate}
      />
    </Dialog>
  );
}
