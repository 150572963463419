export {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
  fetchSignInMethodsForEmail,
  updateProfile,
  setPersistence,
  browserSessionPersistence,
  EmailAuthProvider,
} from "firebase/auth";
export {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  getMetadata,
  listAll,
  deleteObject,
} from "firebase/storage";
export {
  collection,
  getDocs,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  setDoc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  runTransaction,
  deleteField,
  getCountFromServer,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  documentId,
  increment,
  startAfter,
  collectionGroup,
} from "firebase/firestore";

export {
  getMessaging,
  getToken as getTokenPushNotification,
  onMessage,
} from "firebase/messaging";

export {
  getMessaging as getMessagingBackground,
  onBackgroundMessage,
} from "firebase/messaging/sw";

export { getToken } from "firebase/app-check";

export { useEffect } from "react";
export {
  db,
  db2,
  auth,
  auth2,
  storage,
  storage2,
  firebaseAppCheck,
  firebaseAppCheck2,
} from "../config/firebaseConfig";

export { default as axios } from "axios";

export const baseURL_KP = "https://api.kaapo.ng/v1";
// export const baseURL_KP = "http://192.168.1.85:8082/v1";
// export const baseURL_KP = "http://192.168.0.170:8083/v1"; // my-Fi
// export const baseURL_KP = "http://192.168.1.43:8083/v1"; // Dotmac

export const baseURL_ET = "https://api.esetech.com.ng/v1";
// export const baseURL_ET = "http://192.168.1.85:8080/v1";
// export const baseURL_ET = "http://192.168.0.170:8080/v1"; // my-Fi
// export const baseURL_ET = "http://192.168.1.43:8080/v1"; // Dotmac
